import PingService from '@/services/PingService';

export default {
  state: {
    data: [],
  },
  getters: {
  },
  mutations: {
    SET_PING_DATA(state, pingData) {
      state.data = pingData;
    },
  },
  actions: {
    fetchPingData({ commit }) {
      PingService.get()
        .then((response) => {
          commit('SET_PING_DATA', response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  modules: {
  },
};
