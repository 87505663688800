<template>
	<nav class="main-nav">
    <ul class="main-nav__list">
      <!--<li v-if="isSubscriptionPaymentVisible" class="main-nav__item"><router-link :to="{name: 'subscription-payment'}"><MenuItem><icon icon="Finance---money-euro-circle-line" size="1.875rem"/></MenuItem></router-link></li>-->
      <li class="main-nav__item"><router-link :to="{name: 'dashboard'}"><MenuItem><icon icon="Buildings---home-4-line" size="1.875rem"/></MenuItem></router-link></li>
      <li class="main-nav__item"><router-link :to="{name: 'wallet'}"><MenuItem><icon icon="Finance---wallet-3-line" size="1.875rem"/></MenuItem></router-link></li>
      <li class="main-nav__item"><router-link :to="{name: 'dashboard-sent'}"><MenuItem><icon icon="Business---Preventivi-line" size="1.875rem"/></MenuItem></router-link></li>
      <li class="main-nav__item">
        <router-link :to="{name: 'messages'}">
          <MenuItem>
            <icon icon="Communication---question-answer-line" size="1.875rem"/>
            <div v-if="ping.messages_to_read">
              <div v-if="ping.messages_to_read > 0" class="message-to-read"></div>
            </div>
          </MenuItem>
        </router-link>
      </li>
      <li class="main-nav__item">
        <router-link :to="{name: 'notifications'}">
          <MenuItem>
            <icon icon="Media---notification-3-line" size="1.875rem"/>
            <div v-if="ping.notifications_to_read">
              <div v-if="ping.notifications_to_read > 0" class="message-to-read"></div>
            </div>
          </MenuItem>
        </router-link>
      </li>
      <li class="main-nav__item"><router-link :to="{name: 'profile'}"><MenuItem><icon icon="Users---user-3-line" size="1.875rem"/></MenuItem></router-link></li>
      <li class="main-nav__item"><router-link :to="{name: 'faq'}"><MenuItem><icon icon="Systems---question-line" size="1.875rem"/></MenuItem></router-link></li>
    </ul>
  </nav>
</template>

<script>
import MenuItem from '@/components/atoms/MenuItem.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState } from 'vuex';

export default {
  name: 'MainNav',
  components: {
    MenuItem,
    Icon,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data.user,
      ping: (state) => state.ping.data,
    }),
    isSubscriptionPaymentVisible() {
      if (!this.user || this.user.current_subscription == null) {
        return false;
      }
      return ['created', 'canceled', 'ended', 'error'].includes(this.user.current_subscription?.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-nav {
  &__list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  &__item {
    position: relative;
    &:not(:first-child) {
      margin-left: var(--space-lg);
    }
  }
}
.message-to-read {
  width: 10px;
  height: 10px;
  background: var(--secondary-color-400);
  border-radius: 10px;
  position: absolute;
  right: 2px;
  top: 2px;
}
.main-nav__item .router-link-active.router-link-exact-active {
  .message-to-read {
    display: none;
  }
}
</style>
