<template>
  <div class="container-lg dashboard">
    <div class="dashboard__content">
      <div class="dashboard__title">
        <div>
          <h1>
            Dashboard
            <div v-if="isLoading" class="loader-container">
              <Icon class="loader__icon" icon="Systems---loader-4-fill" size="2rem"/>
            </div>
          </h1>
        </div>
        <SearchInput @onSearch="applyFilters" @onClear="applyFilters"/>
      </div>
      <DashboardFilters @onApplyFilters="applyFilters" @onSortData="sortingData"/>
      <div class="dashboard__header main-header">
        <DashboardNav/>
        <!-- <Sort @onSortingData="sortingData"/> -->
      </div>
      <router-view v-slot="{ Component }">
        <component ref="dashboardPage" :is="Component" />
      </router-view>
    </div>
  </div>
</template>

<script>
import DashboardNav from '@/components/molecules/DashboardNav.vue';
import DashboardFilters from '@/components/molecules/DashboardFilters.vue';
import Sort from '@/components/atoms/Sort.vue';
import SearchInput from '@/components/atoms/form/SearchInput.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import { mapGetters, mapActions } from 'vuex';

// import dealerCron from '@/mixins/dealerCron';

export default {
  name: 'Dashboard',
  // mixins: [dealerCron],
  components: {
    DashboardNav,
    DashboardFilters,
    Sort,
    SearchInput,
    Icon,
  },
  data() {
    return {
      loading: true
    };
  },
  created() {
    this.$store
      .dispatch('fetchUserData');
    this.$store
      .dispatch('fetchDrafts');
    this.$store
      .dispatch('fetchParts');

    // this.dealerDataUpdate(5000);
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
    }),
  },
  methods: {
    ...mapActions({
      setLoading: 'ui/setLoading',
    }),
    applyFilters() {
      this.$refs.dashboardPage?.fetchData();
    },
    sortingData() {
      this.$refs.dashboardPage?.sortingData();
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  &__content  {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4.375rem);
    overflow: hidden;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      display: flex;
      align-items: center;
      .loader-container {
        margin-left: var(--space-sm);
        display: flex;
        align-items: center;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    > :first-child {
      flex-grow: 1;
    }
  }
  &__columns {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  &__column-left {
    position: relative;
    width: 40%;
    overflow-y: auto;
    padding: 0 var(--space-sm);
    display: flex;
    flex-direction: column;
  }
  &__column-right {
    width: 60%;
    overflow-y: auto;
    padding: 0 var(--space-sm);
  }
  &__pagination {
    position: sticky;
    bottom: 0;
    height: 3.125rem;
    background-color: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
    padding: var(--space-sm) 0;
  }
}
.main-header {
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: silver;
    position: absolute;
    left: 0;
    bottom:var(--space-sm);
    transform: translateY(-0.5px);
  }
}
.loader {
  text-align: center;
  display: flex;
  &__icon {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
