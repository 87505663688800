<template>
	<div class="tag">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tag',
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  border: .0625rem solid var(--primary-color-400);
  font-weight: bold;
  border-radius: .3125rem;
  padding: var(--space-xxxs);
  background: #fff;
  margin: var(--space-xxxs);
  font-size: var(--text-sm);

  &__paid {
    background-color: var(--secondary-color-400);
    border-color: var(--secondary-color-400);
  }
  &__to-pay {
    border-color: var(--secondary-color-400);
  }
  &__to-pay {
    border-color: var(--secondary-color-400);
  }
  &__shipped {
    border-color: var(--first-neutral-color-400);
    background-color: var(--first-neutral-color-400);
  }
  &__canceled {
    color: var(--secondary-neutral-color-200);
    background-color: var(--error-color);
    border-color: var(--secondary-neutral-color-200);
  }
  &__to-ship {
    border-color: var(--first-neutral-color-400);
  }
  &__offline {
    border-color: var(--tertiary-color-200);
    background-color: var(--tertiary-color-200);
  }
}
</style>
