import { sha256 } from 'js-sha256';
// eslint-disable-next-line
import apiClient from './apiClient';

export default {
  get() {
    return apiClient.get('/user');
  },
  login(email, password) {
    const hashComune = process.env.VUE_APP_HASH_COMUNE;
    const saltHash = [...Array(64)].map(() => Math.random().toString(36)[2]);
    const salt = saltHash.filter((c, i) => i % 2).join('').substring(12, 24);

    return apiClient.post('/user/login', {
      email,
      password,
      security: JSON.stringify({ f: window.btoa(sha256(`${salt}${hashComune}${salt}`)), v: window.btoa(saltHash.join('')) }),
    });
  },
  getPaymentSetup(id) {
    return apiClient.post(`/dealer/${id}/payment/setup`);
  },
  getPaymentUpdate(id) {
    return apiClient.post(`/dealer/${id}/payment/update`);
  },
  updateUserData(userID, formData) {
    return apiClient.post(`/dealer/${userID}/update`, formData);
  },
  recoveryPassword(formData) {
    return apiClient.post('/user/forgot-password', formData);
  },
  buyCredits(id, formData) {
    return apiClient.post(`/dealer/${id}/credits/buy`, formData);
  },
  checkCoupon(id, formData) {
    return apiClient.post(`/dealer/${id}/coupon/check`, formData);
  },
  getProvinces(id) {
    return apiClient.get(`/dealer/${id}/provinces/list`);
  },
  updatePassword(id, formData) {
    return apiClient.post(`/dealer/${id}/password/update`, formData);
  },
  updateIban(id, formData) {
    return apiClient.post(`/dealer/${id}/iban/update`, formData);
  },
  updateFee(id, formData) {
    return apiClient.post(`/dealer/${id}/fees/update`, formData);
  },
  updatePhoto(id, formData) {
    return apiClient.post(`/dealer/${id}/photo/update`, formData);
  },
  updateProvinces(id, formData) {
    return apiClient.post(`/dealer/${id}/provinces/update`, formData);
  },
  updatePaymentMethods(id, formData) {
    return apiClient.post(`/dealer/${id}/methods/update`, formData);
  }, 
  updateOrder(formData) {
    console.log(formData);
    return apiClient.post(`/payment/paypal/webhook`, formData);
  },
  getFeeAmount(id, formData) {
    return apiClient.get(`/dealer/${id}/fee-amount`, formData);
  }
};
