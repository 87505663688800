<template>
  <WhiteContainer class="quotation-sent">
    <div class="container-xs">
      <h1>PREVENTIVO INVIATO</h1>
      <h3>Puoi visualizzare il preventivo direttamente dalla sezione della Dashboard nella sezione Inviati.</h3>
      <div class="quotation-sent__actions">
        <router-link :to="{name: 'dashboard-sent'}"><Btn class="btn__primary" @click="closeModal()">Visualizza Preventivi</Btn></router-link>
      </div>
    </div>
  </WhiteContainer>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';

export default {
  name: 'QuotationSent',
  components: {
    Btn,
    WhiteContainer,
  },
  methods: {
    closeModal() {
      this.$store
        .dispatch('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.quotation-sent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin: var(--space-xxl) 0;
  }
  &__actions {
    margin-top: var(--space-md);
    display: flex;
    justify-content: center;
  }
}
</style>
