<template>
    <WhiteContainer class="payment-infos">
        <div class="container">
        <div class="logo">
            <img v-if="pingData.logo" :src="pingData.logo.vertical.color" alt="Logo">
        </div>
        <h3>Di seguito i dati per effettuare il bonifico :</h3>
        <div class="payment-info-container">
            <p><span class="payment-subtitle">Intestatario:</span> PartsLab S.r.l.</p>
            <p><span class="payment-subtitle">Banca:</span> Banco BPM</p>
            <p><span class="payment-subtitle">IBAN:</span> IT17L0503414027000000001932</p>
        </div>
      </div>
    </WhiteContainer>
  </template>
  
  <script>
  import Btn from '@/components/atoms/Btn.vue';
  import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
  
  import { mapState } from 'vuex';
  import Icon from '@/components/atoms/icon/Icon.vue';
  import Logo from '@/components/atoms/Logo.vue';
  
  export default {
    components: {
      Btn,
      WhiteContainer,
      Icon,
      Logo
    },
    methods: {
      closeModal() {
        this.$store
          .dispatch('closeModal');
      },
    },
    computed: mapState({
    pingData: (state) => state.ping.data,
  }),
  };
  </script>
  
  <style lang="scss" scoped>
    .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        max-height: 120px;
    }
  .payment-infos {
    display: flex;
    p {
        font-size: 18px;
    }
    .payment-subtitle {
        font-size: 18px;
        font-weight: bold;
    }
    .payment-info-container {
        background: #1128485b;
        padding: 10px 20px;
        border-radius: 20px;
    }
  }
  </style>
  