<template>
	<div class="tab">
    <h2 class="tab__title"><slot /></h2>
    <div class="tab__counter" :class="{'animate': animate}" v-if="counter > 0">
      <span>{{counter}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    counter: {
      type: Number,
      default: 0,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  color: var(--primary-color-250);
  border-bottom: .125rem solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    margin: var(--space-xs) 0;
    text-transform: uppercase;
    font-size: var(--text-xl);
  }
  &__counter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    background-color: currentColor;
    border-radius: 100%;
    font-size: var(--text-xs);
    margin-left: var(--space-sm);
    position: relative;
    span {
      color: #fff;
      line-height: 1;
      font-size: 0.6rem;
    }
    &.animate::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      background-color: var(--primary-color-400);
      border-radius: 100%;
      font-size: var(--text-xs);
      z-index: -1;
      animation: ripple 1.5s linear 2;
    }
  }
  .router-link-exact-active & {
    color: var(--primary-color-350);
    border-bottom: .125rem solid currentColor;
  }
  // &--active {
  //   color: var(--primary-color-350);
  // }

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
}
</style>
