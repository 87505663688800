<template>
  <WhiteContainer class="quotation-sent">
    <div class="container-xs">
      <h2>STAI EFFETTUANDO LA RICHIESTA DI DISDETTA DEL TUO ABBONAMENTO, SEI SICURO?</h2>
      <p>La richiesta di disdetta è da effettuarsi tramite l'invio di una e-mail a {{unsubscribeEmail}}.<br/><small>Qualsiasi altra comunicazione non sarà ritenuta valida.</small></p>
      <div class="quotation-sent__actions">
        <Btn class="btn__primary" @click="closeModal()">No, torna indietro</Btn>
        <a :href="`mailto:${unsubscribeEmail}`">Scrivi a {{unsubscribeEmail}}</a>
      </div>
    </div>
  </WhiteContainer>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';

export default {
  name: 'Unsubscribe',
  components: {
    Btn,
    WhiteContainer,
  },
  computed: {
    unsubscribeEmail() {
      return process.env.VUE_APP_UNSUBSCRIBE_EMAIL;
    },
  },
  methods: {
    closeModal() {
      this.$store
        .dispatch('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.quotation-sent {
  display: flex;
  text-align: center;
  h3 {
    margin: var(--space-xxl) 0;
  }
  &__actions {
    margin-top: var(--space-xl);
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: var(--text-md);
      color: var(--primary-color-400);
    }
  }
}
</style>
