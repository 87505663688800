<template>
	<div class="dashboard-header">
    <div class="dashboard-header__col">
      <slot name="content-left"></slot>
    </div>
    <div class="dashboard-header__col dashboard-header__col--center">
      <slot name="content-center"></slot>
    </div>
    <div class="dashboard-header__col dashboard-header__col--right">
      <slot name="content-right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardHeader',
};
</script>

<style lang="scss" scoped>
.dashboard-header {
  display: flex;
  padding: var(--space-sm) 0;
  position: relative;
  &__col {
    width: calc(40% - var(--space-xs));
    &--center {
      width: calc(20% - var(--space-xs));
      margin: 0 var(--space-sm);
    }
    &--right {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}
</style>
