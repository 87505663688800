import ProvinceService from '@/services/ProvinceService';

export default {
  state: {
    data: [],
  },
  getters: {
    provinceOptions(state) {
      return state.data.map((elm) => ({ label: elm.name, value: elm.id }));
    },
    getProvince: (state) => (id) => state.data.find((elm) => elm.id === Number(id)),
  },
  mutations: {
    SET_PROVINCE_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchProvinceData({ commit }) {
      ProvinceService.get()
        .then((response) => {
          commit('SET_PROVINCE_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  modules: {
  },
};
