<template>
	<div class="timer">
    <div v-if="frozen == 0">
      <div class="timer__time text--xxl">{{timer}}'<icon icon="Systems---time-fill" size="0.938rem"/></div>
      <div class="timer__label text--xs">Timing residuo</div>
    </div>
    <div v-else>
      <div class="timer__time frozen text--xxl">{{time}}'<icon icon="Systems---time-fill" size="0.938rem"/></div>
      <div class="timer__label frozen text--xs">Timing in pausa</div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Timer',
  props: {
    time: {
      type: Number,
      default: 0,
    },
    frozen: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      timer: this.time,
    };
  },
  mounted() {
    if (this.time > 0) {
      const countdown = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(countdown);
        } else {
          this.timer--;
        }
      }, 60 * 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.timer {
  text-align: center;
  &__time {
    font-weight: bold;
    color: var(--primary-color-400);
  }
  &__time, &__label {
    &.frozen {
      color: var(--warning-color);
    }
  }
  .frozen {
    color: var(--warning-color);
  }
}

</style>
