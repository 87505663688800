import DiscoveryService from '@/services/DiscoveryService';

export default {
  state: {
    data: [],
  },
  getters: {
    faqs(state) {
      return state.data.faq ? state.data.faq.it.data : [];
    },
  },
  mutations: {
    SET_DISCOVERY_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchDiscoveryData({ commit }) {
      DiscoveryService.get()
        .then((response) => {
          commit('SET_DISCOVERY_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  modules: {
  },
};
