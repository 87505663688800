import PaymentMethodsService from '@/services/PaymentMethodsService';

export default {
  state: {
    data: [],
  },
  getters: {
  },
  mutations: {
    SET_PAYMENT_METHODS_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchPaymentMethodsData({ commit }) {
      return new Promise((resolve, reject) => {
        PaymentMethodsService.getAll()
          .then((response) => {
            commit('SET_PAYMENT_METHODS_DATA', response.data.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {
  },
};
