import dayjs from 'dayjs';

export default {
  state: {
    manufacturers: [],
    parts: [],
    provinces: [],
    search: null,
    dateFrom: null,
    dateTo: null,
    sort: false,
  },
  getters: {
    isSorting: (state) => state.sort,
  },
  mutations: {
    SET_manufacturers_FILTER(state, payload) {
      state.manufacturers = payload;
    },
    SET_PARTS_FILTER(state, payload) {
      state.parts = payload;
    },
    SET_PROVINCES_FILTER(state, payload) {
      state.provinces = payload;
    },
    SET_SEARCH_TEXT(state, payload) {
      state.search = payload;
    },
    SET_DATEFROM_FILTER(state, payload) {
      state.dateFrom = payload ? dayjs(payload).format('YYYY-MM-DD') : null;
    },
    SET_DATETO_FILTER(state, payload) {
      state.dateTo = payload ? dayjs(payload).format('YYYY-MM-DD') : null;
    },
    SET_SORT_STATUS(state, payload) {
      state.sort = !state.sort;
    },
    RESET_FILTERS(state) {
      state.manufacturers = [];
      state.parts = [];
      state.provinces = [];
      state.dateFrom = null;
      state.dateTo = null;
      state.sort = false;
    },
    RESET_SEARCH_TEXT(state) {
      state.search = '';
    },
  },
  actions: {
    setDashboardFilters({ commit }, payload) {
      commit('SET_manufacturers_FILTER', payload.manufacturers);
      commit('SET_PARTS_FILTER', payload.parts);
      commit('SET_PROVINCES_FILTER', payload.provinces);
      commit('SET_DATEFROM_FILTER', payload.dateFrom);
      commit('SET_DATETO_FILTER', payload.dateTo);
    },
    setSearchText({ commit }, payload) {
      commit('SET_SEARCH_TEXT', payload);
    },
    resetDashboardFilters({ commit }) {
      commit('RESET_FILTERS');
    },
    resetSearchText({ commit }) {
      commit('RESET_SEARCH_TEXT');
    },
    setSort({ commit, dispatch }) {
      commit('SET_SORT_STATUS');
      // dispatch('sortingSubmissions', { root: true });
      // dispatch('sortingQuotations', { root: true });
    },
  },
  modules: {
  },
};
