<template>
  <div class="sort" @click="handleClick()">
    <div class="mr-space-xs">
      {{ sorting ? 'Più recenti' : 'Meno recenti' }}
    </div>
    <icon
      icon="Systems---arrow-up-down-line"
      :class="{'disabled' : !sorting}"
      size="1.563rem"
    />
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Sort',
  components: {
    Icon,
  },
  props: {
    sorting: {
      type: Boolean,
    }
  },
  computed: {
    // internalModelValue: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   set(value) {
    //     this.$emit('update:modelValue', value);
    //   }
    // },
  },
  methods: {
    handleClick() {
      // this.modelValue = !this.modelValue;
      // this.$emit('update:modelValue', !this.internalModelValue)
      this.$emit('onSortingData');
    }
  }
};
</script>

<style lang="scss" scoped>
.sort {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.disabled {
    color: var(--primary-color-250);
  }
</style>
