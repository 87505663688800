<template>
  <div class="container-lg wallet">
    <h1>Wallet</h1>
    <div class="wallet__icon"><Icon icon="Finance---wallet-3-fill" size="7.5rem"/></div>
    <WhiteContainer class="mb-space-lg">
      <div class="p-space-md">
        <h2 class="mt-0"><Icon icon="Finance---coin-fill" size="1.375rem" class="mr-space-xs"/> I TUOI CREDITI</h2>
        <div class="row align-center text-center py-space-md">
          <div class="col">
            <Counter :number="userData.credits" :inColumn="true" label="Crediti Residui"><Icon icon="Finance---coin-fill" size="1.875rem"/></Counter>
          </div>
          <div class="col">
          </div>
          <div class="col">
            <div class="">
              <router-link class="router-btn" :to="{name: 'purchase-credits'}"><Btn class="btn__primary" style="display: inline-flex; align-items: center"><Icon icon="Finance---coin-fill" size="1.875rem" class="mr-space-sm"/> ACQUISTA CREDITI</Btn></router-link>
              
              <router-link class="router-btn" :to="{name: 'pay-fee'}"><Btn class="btn__primary" style="display: inline-flex; align-items: center"><Icon icon="Finance---coin-fill" size="1.875rem" class="mr-space-sm"/> SALDA FEE</Btn></router-link>
            </div>

            <!-- <div>RICARICA AUTOMATICA ATTIVA</div> -->
            <!-- <div class="text--xs">Per modificare vai alla sezione del tuo <router-link :to="{name: 'profile'}">profilo</router-link></div> -->
          </div>
          
        </div>
      </div>
    </WhiteContainer>
    <WhiteContainer>
      <div class="p-space-md">
        <h2 class="mt-0"><Icon icon="Business---Preventivi-line" size="1.375rem" class="mr-space-xs"/> ULTIMI {{totalQuotations}} PREVENTIVI ACCETTATI</h2>
        <QuotationsTable :quotations="quotations"/>
      </div>
    </WhiteContainer>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Btn from '@/components/atoms/Btn.vue';
import QuotationsTable from '@/components/molecules/QuotationsTable.vue';
import Counter from '@/components/molecules/Counter.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Wallet',
  components: {
    WhiteContainer,
    Btn,
    QuotationsTable,
    Counter,
    Icon,
  },
  created() {
    this.$store.dispatch('fetchUserData');
    this.$store
      .dispatch('fetchQuotations', { filter: 'accepted', page: 1, disableFilters: true });
  },
  computed: mapState({
    userData: (state) => state.user.data.user,
    quotations: (state) => state.quotations.data,
    ...mapGetters([
      'totalQuotations',
    ]),
  }),
};
</script>

<style lang="scss" scoped>
.wallet {
  a {
    text-decoration: none;
  }
  &__icon {
    text-align: center;
    color: var(--primary-color-400);
    margin-bottom: var(--space-md);
  }

  .router-btn {
    width: 80% !important;
    display: block;

    & button {
      width: 100%;
      justify-content: center;
    }

    &:first-child {
        margin-bottom: 10px;
    }
  }
}
</style>
