<template>
  <div class="container-lg feedback">
    <WhiteContainer>
      <div class="container-xs p-space-md">
        <h1>Lascia un feedback</h1>
        <ol class="feedback__rows">
          <li class="feedback__row" v-for="(feedback, i) in feedbacks" :key="feedback.id">
            <div class="feedback__title">{{feedback.question_text}}</div>
            <div class="feedback__options">
              <label class="feedback__option" v-for="option in feedback.question_options" :key="option.question_id" :for="`question-${option.question_id}-${option.score}`">
                <img :src="option.image" :alt="option.label">
                <div class="feedback__option-label">{{option.label}}</div>
                <input class="feedback__option-field" type="radio" v-if="formData[i]" v-model="formData[i].answer_id" :value="option.score" :name="option.question_id" :id="`question-${option.question_id}-${option.score}`">
              </label>
            </div>
            <div class="feedback__note" v-if="formData[i]" v-show="feedback.question_options.find((elm) => elm.score === formData[i].answer_id)?.show_textarea">
              <label for="message">Che cosa non è andatato bene?
                <textarea v-if="formData[i]" v-model="formData[i].message" id="message" cols="30" rows="5" placeholder="Scrivere qui qui"></textarea>
              </label>
            </div>
          </li>
        </ol>
        <div class="feedback__submit">
          <Btn class="btn__primary">Invia</Btn>
        </div>
      </div>
    </WhiteContainer>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';

import { mapState } from 'vuex';
import Btn from '@/components/atoms/Btn.vue';

export default {
  name: 'Feedback',
  components: {
    WhiteContainer,
    Btn,
  },
  data() {
    return {
      formData: [],
    };
  },
  created() {
    this.$store.dispatch('fetchFeedback').then(() => {
      this.formDataReset();
    });
  },
  computed: mapState({
    feedbacks: (state) => state.feedback.data,
  }),
  methods: {
    formDataReset() {
      this.feedbacks.forEach((elm) => {
        this.formData.push({
          feedback_question_id: elm.id,
          answer_id: 0,
          message: '',
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  padding-top: var(--space-xl);

  &__row {
    margin: var(--space-lg) 0;
  }

  &__title {
    margin-bottom: var(--space-sm);
  }

  &__options {
    display: flex;
  }

  &__option {
    margin-right: var(--space-lg);
    text-align: center;
  }
  &__option-label {
    position: relative;
    font-size: var(--text-xs);
  }
  &__option-field {
    position: absolute;
    opacity: 0;
  }
  &__submit {
    text-align: center;
  }
}
</style>
