import SubmissionsService from '@/services/SubmissionsService';

export default {
  state: {
    data: [],
    localStorage: null,
    draftID: null,
    draftActive: 0,
    currentPage: 1,
  },
  getters: {
    totalDrafts(state) {
      return state.data.length;
    },
    draftActiveIndex(state) {
      const index = state.data.findIndex((elm) => elm.id === state.draftID);
      if (index === -1) {
        return state.draftActive;
      }
      if (index >= state.data.length - 1) {
        state.currentPage = Math.ceil((index + 1) / 8);
        return index % 8;
      }
      return index;
    },
    draftActiveData(state, getters) {
      return state.data[getters.draftActiveIndex];
    },
    totalPages(state, getters) {
      return Math.ceil(getters.totalDrafts / 8);
    },
    drafts(state) {
      return state.data.slice((state.currentPage - 1) * 8, state.currentPage * 8);
    },
  },
  mutations: {
    SET_DRAFTS(state, drafts) {
      state.data = drafts;
    },
    SET_DRAFT_ID(state, id) {
      state.draftID = id;
    },
    ADD_DRAFT(state, draft) {
      state.data.push(draft);
    },
    SET_DRAFTS_LOCAL_STORAGE(state, drafts) {
      state.localStorage = drafts;
    },
    SET_DRAFT_ACTIVE(state, i) {
      state.draftActive = i;
    },
    SET_CURRENT_PAGE(state, newPage) {
      state.currentPage = newPage;
    },
  },
  actions: {
    fetchDrafts({ commit, dispatch }) {
      let drafts = localStorage.getItem('quotationsDrafts');
      commit('SET_DRAFTS', { data: [] });
      if (drafts) {
        dispatch('setLoading', true, { root: true });

        drafts = JSON.parse(drafts);
        commit('SET_DRAFTS_LOCAL_STORAGE', drafts);
        const ids = drafts.map((elm) => elm.id);
        const promises = ids.map((id) => SubmissionsService.get(id));
        Promise.allSettled([...promises]).then((responses) => {
          const data = responses.filter((response) => response.status === 'fulfilled').map((response) => response.value.data.data);
          const rejects = responses.filter((response) => response.status === 'rejected');
          if (rejects.length > 0) {
            rejects.forEach((reject) => {
              const id = reject.reason.request.responseURL.split('/').pop();
              dispatch('removeDraft', id);
            });
          }
          commit('SET_DRAFTS', data);
        }).catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch('setLoading', false, { root: true });
        });
      }
    },
    changeDraftActive({ commit }, i) {
      commit('SET_DRAFT_ID', null);
      commit('SET_DRAFT_ACTIVE', i);
    },
    addDraft({ commit }, draft) {
      return new Promise((resolve, reject) => {
        try {
          let drafts = localStorage.getItem('quotationsDrafts');

          if (drafts) {
            drafts = JSON.parse(drafts);
            const draftExist = drafts.findIndex((elm) => elm.id === draft.id);
            if (draftExist >= 0) {
              drafts[draftExist] = draft;
            } else {
              drafts.unshift(draft);
            }
          } else {
            drafts = [draft];
          }
          localStorage.setItem('quotationsDrafts', JSON.stringify(drafts));
          commit('SET_DRAFT_ID', draft.id);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    removeDraft({ commit }, id) {
      let drafts = localStorage.getItem('quotationsDrafts');
      if (drafts) {
        drafts = JSON.parse(drafts);
        drafts = drafts.filter((draft) => draft.id !== Number(id));
        commit('SET_DRAFTS_LOCAL_STORAGE', drafts);
        localStorage.setItem('quotationsDrafts', JSON.stringify(drafts));
      }
    },
    updateDraft({ commit, state }, data) {
      const drafts = state.localStorage.map((elm) => {
        if (elm.id === data.id) {
          return data;
        }
        return elm;
      });
      commit('SET_DRAFTS_LOCAL_STORAGE', drafts);
      localStorage.setItem('quotationsDrafts', JSON.stringify(drafts));
    },
    changePage({ commit }, newPage) {
      commit('SET_CURRENT_PAGE', newPage);
    },
  },
  modules: {
  },
};
