<template>
  <WhiteContainer class="quotation-sent">
    <div class="container-xs">
      <template v-if="!payment.error">
      <h1>Salvataggio in corso</h1>
      <div>
        <Icon class="quotation-sent__icon" icon="Systems---loader-4-fill" size="5rem"/>
      </div>
      <h3>Stiamo effettuando il salvataggio dei tuoi dati</h3>
      </template>
      <template v-else>
      <h2>Errore</h2>
      <h3>{{payment.error}}</h3>
      <div class="quotation-sent__actions">
        <Btn class="btn__primary" @click="closeModal()">Torna alla pagina di modifica</Btn>
      </div>
      </template>
    </div>
  </WhiteContainer>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';

import { mapState } from 'vuex';
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Payment',
  components: {
    Btn,
    WhiteContainer,
    Icon,
  },
  methods: {
    closeModal() {
      this.$store
        .dispatch('closeModal');
      this.$store
        .dispatch('paymentError', '');
    },
  },
  computed: mapState({
    payment: (state) => state.ui.payment,
  }),
};
</script>

<style lang="scss" scoped>
.quotation-sent {
  text-align: center;
  display: flex;
  &__icon {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
