<template>
	<div class="logo">
    <img v-if="pingData.logo" :src="pingData.logo.horizontal.light" alt="Logo">
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Logo',
  computed: mapState({
    pingData: (state) => state.ping.data,
  }),
};
</script>

<style lang="scss" scoped>
.logo {
	height: 45px;
  img {
    height: 100%;
  }
}
</style>
