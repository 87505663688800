<template lang="">
    <div style="padding:20px;margin: 0 auto;">
        <div style="display: flex;width: 100%; margin-bottom: 15px;">
            <div style="border-right: 1px solid #112848;width: 50%;">
                <img style="width: auto;" src="../../../assets/img/logo_parz.png" alt="logo">
                <div style="display:flex;justify-content:space-between;align-items:center;padding-right:20px;margin-bottom:10px;">
                    <h4 style="color:#112848;font-size:24px;">Riepilogo preventivo</h4>
                    <p>Data: {{formatData(pdfData.accepted_date || pdfData.created_at)}}</p>
                </div>
            </div>

            <div style="padding-left: 20px;">
                <h3 style="margin-bottom: 10px;">Veicolo: </h3>
                <div style="line-height: 25px">
                    <div style="margin-right: 40px;font-size: 16px;">
                        <div style="word-break: break-word;">{{pdfData.vehicle.plate ? pdfData.vehicle.plate : pdfData.vehicle.chassis}}</div>
                        <div>{{manufacturer(pdfData.vehicle.manufacturer_id)?.name}} {{ pdfData.vehicle.model ? ` - ${pdfData.vehicle.model}` : '' }}</div>
                    </div>
                    <div style="font-size: 16px;">
                        <div>Anno {{quotationActiveData.vehicle.year_of_production}}</div>
                        <div>Km {{getKmValue(quotationActiveData.vehicle.km_count)}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div  v-if="pdfData.user" style="width: 100%;height: 1px;background-color: black;margin-bottom: 25px;"></div>

        <div style="margin-bottom: 25px;"  v-if="pdfData.user">
            <div style="line-height: 25px">
                <h3 style="margin-bottom: 10px;">Dati cliente:</h3>
                <div>{{pdfData.user?.name}} {{pdfData.user.surname}}</div>
                <div v-if="pdfData.user.fiscal_code">C.F {{pdfData.user.fiscal_code}}</div>
                <div class="contacts-data" v-if="pdfData.user.email">Email. <a :href="`mailto:${pdfData.user.email}`">{{ pdfData.user.email }}</a>
                </div>
                <div class="contacts-data" v-if="pdfData.user.phone">Tel. <a :href="`tel:${pdfData.user.phone}}`">{{ pdfData.user.phone }}</a>
                </div>
                <div v-if="pdfData.user.adr_street">{{pdfData.user.adr_street}},</div>
                <div>{{pdfData.user.adr_city}} {{pdfData.user.adr_zip_code}}</div>
            </div>
        </div>

        <div style="width: 100%;height: 1px;background-color: black;margin-bottom: 25px;"></div>

        <table style="width: 100%;">
            <tr style="border-width:1px;border-color:#112848;border-style: solid;width: 800px;background-color: #9DA9B7;">
                <th style="border-right-color:#112848;border-right-style: solid; padding: 1px 6px;"
                v-for="(title, i) in tableColumnTitles" :key="i">{{ title }}</th>
            </tr>
            <tr v-for="(row, i) in pdfData.quotation_rows" :key="i">
                <td>{{row.accepted ? 'Accettato' : 'Non accettato'}}</td>
                <td>{{row.quantity}}</td>
                <td>{{breadcrumbs(row.part_id)}}</td>
                <td>{{row.part_code}}</td>
                <td>{{row.brand}}</td>
                <td>{{getConditionLabel(row.condition)}}</td>
                <td>{{row.net_price}}</td>
                <td>{{row.tax_import}}</td>
            </tr>
        </table>

        <div style="margin-top: 20px; text-align: right;">
            <h3 style="text-align: left;">Importo:</h3>

            <div style="line-height: 25px">
                <div>Totale netto € {{pdfData.subtotal_price.toFixed(2)}}</div>
                <div>IVA totale € {{pdfData.total_tax.toFixed(2)}}</div>
                <div v-if="pdfData.shipping_cost_flat && (pdfData.status == 'pending' || pdfData.shipping_fee_chosen == 'flat')">
                Spedizione base € {{pdfData.shipping_cost_flat.toFixed(2)}}
                </div>
                <div v-if="pdfData.shipping_cost_partial && (pdfData.status == 'pending' || pdfData.shipping_fee_chosen == 'partial')">
                Spedizione parziale € {{pdfData.shipping_cost_partial.toFixed(2)}}
                </div>
                <div style="width: 100%;height: 1px;background-color: black;margin: 15px 0;"></div>
                <h3 style="margin-top: 0">Totale € {{pdfData.total_price.toFixed(2)}}</h3>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import timeMixin from '@/mixins/timeMixin';

export default {
    mixins: [timeMixin],
    components: {
        timeMixin
    },
    props: {
        pdfData: [Object, String, Array]
    },

    data() {
        return {
            tableColumnTitles: [
                'Stato',
                'Quantità',
                'Categoria',
                'Codice',
                'Marca',
                'Condizione',
                'Prezzo',
                'Iva'
            ]
        }
    },

    mounted() {
    },

    computed: mapState({
        submissionActive: (state) => state.submissions.submissionActive,
        submissions: (state) => state.submissions.data,
        totalPages: (state) => state.submissions.totalPages,
        ...mapGetters([
        'totalSubmissions',
        'quotationActiveData',
        'parentPart',
        'manufacturer',
        'getKmValue',
        'breadcrumbs',
        ]),
        ...mapState({
        user: (state) => state.user.data.user,
        }),
    }),

    methods: {
        getConditionLabel(key) {
            const conditions = {
                new: 'Nuovo',
                used: 'Usato',
                reconditioned: 'Ricondizionato',
            };

            return conditions[key] || '';
        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress() {
            console.log('On progress');
        },
        hasStartedGeneration() {
            console.log('On start');
        },
        hasGenerated() {
            console.log('Done');
        },
        generateRows() {
            this.$props.submissonRows.forEach(submissionRow => {

            });
        },
    }
}
</script>
