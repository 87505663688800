<template>
  <div class="row">
    <div class="col-6">
      <template v-if="!isEditing">
        <div>Email {{user_email}}</div>
        <div>Pass *******</div>
      </template>
      <template v-else>
        <BaseInput label="Vecchia Password" type="password" v-model="formData.old_password" required/>
      </template>
    </div>
    <div class="col-6">
      <template v-if="isEditing">
        <BaseInput label="Nuova Password" type="password" v-model="formData.password" minlength="8" required/>
        <BaseInput label="Ripeti nuova Password" type="password" v-model="formData.password_confirmation" minlength="8" required/>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseInput from '@/components/atoms/form/BaseInput.vue';

export default {
  name: 'updatePassword',
  components: {
    BaseInput,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  computed: {
    ...mapState({
      user_email: (state) => state.user.data.user.email,
    }),
  },
  methods: {
    validation() {
      if (this.formData.password !== '' || this.formData.password_confirmation !== '') {
        if (this.formData.password !== this.formData.password_confirmation) {
          throw Error('Le password non coincidono');
        }
      }
    },
  },
};
</script>
