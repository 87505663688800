<template>
  <div class="dealer-avatar">
    <div class="dealer-avatar__content">
      <div class="dealer-avatar__icon">
        <Icon icon="Design---pencil-fill" size="1.25rem"/>
        <label for="avatar">
          <input @change="uploadFile" class="dealer-avatar__field" type="file" accept="image/png, image/jpeg">
        </label>
      </div>
      <Avatar :image="profilePhoto" />
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState } from 'vuex';

export default {
  name: '',
  components: {
    Avatar,
    Icon,
  },
  computed: {
    ...mapState({
      profilePhoto: (state) => state.user.data.user.profile_photo,
    }),
  },
  methods: {
    uploadFile(event) {
      const file = event.srcElement.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        this.$store.dispatch('uploadUserImage', window.btoa(reader.result));
      };
      reader.onerror = function () {
        console.log('there are some problems');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dealer-avatar {
  display: flex;
  justify-content: center;
  &__icon {
    position: absolute;
    top: 0;
    right: 1.25rem;
    width: 1.625rem;
    height: 1.625rem;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--primary-color-400);
    box-shadow: 0 1px 3px var(--gray-color-350);
    svg {
      margin-top: .125rem;
      margin-left: .125rem;
    }
    * {
      cursor: pointer;
    }
  }
  &__field {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }
  &__content {
    position: relative;
  }
}
</style>
