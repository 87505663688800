<template>
  <header class="header">
    <div class="container-lg header__container">
      <router-link :to="{name: 'dashboard'}"><Logo /></router-link>
      <MainNav/>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/atoms/Logo.vue';
import MainNav from '@/components/molecules/MainNav.vue';

export default {
  name: 'Header',
  components: {
    Logo,
    MainNav,
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: var(--primary-color-400);
  height: 4.375rem;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
