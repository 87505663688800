<template>
    <div class="container-lg subscription">
      <h1>Saldo Fee</h1>
      <div class="row">
        <WhiteContainer class="col-6">
          <div class="p-space-md">
            <div class="d-flex justify-between align-center">
              <div>Totale</div>
              <div class="text--xxl">€ {{totalOrder}}</div>
            </div>
            <h4>Dettaglio pagamento:</h4>
            <div class="mb-space-md" v-for="order in orderRows" :key="order.id">
              <Detail :itemAmount="order.item_amount" :itemCount="order.item_count" :itemName="order.item_name"/>
            </div>
            <div class="d-flex justify-end">
              <div class="text-right">
                <h5>Totale Parziale € {{totalOrder}}</h5>
                <hr>
                <h4>Totale € {{totalOrder}}</h4>
                <div class="text--xs">*Il totale è iva esclusa. La cifra compresa di iva sarà visibile proseguendo con il pagamento</div>
              </div>
            </div>
          </div>
        </WhiteContainer>
        <WhiteContainer class="col-6 d-flex align-end">
          <!-- <div class="p-space-md">
            <template v-if="clientSecret">
              <Stripe :clientSecret="clientSecret" :publicKey="publicKey"/>
            </template>
          </div> -->
          <div v-if="order" style="width: 80%; margin: auto;">
            <Paypal v-if="clientId" :client_id="clientId" :order="order"></Paypal>
          </div>
          <div v-else class="col-12 d-flex flex-column align-center p-4">
            <h2 class="text-center">Congratulazioni!</h2>
            <p>Tutte le fee risultano pagate</p>
          </div>
        </WhiteContainer>
      </div>
    </div>
  </template>
  
  <script>
  import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
  import Detail from '@/components/atoms/Detail.vue';
  import BaseSelect from '@/components/atoms/form/BaseSelect.vue';
  import BaseInput from '@/components/atoms/form/BaseInput.vue';
  import Btn from '@/components/atoms/Btn.vue';
  import Icon from '@/components/atoms/icon/Icon.vue';
  import Counter from '@/components/molecules/Counter.vue';
  import Paypal from '@/components/atoms/Paypal.vue';
  
  import { mapState } from 'vuex';
  
  export default {
    name: 'PayFee',
    data() {
      return {
        order: null,
        paymentUniqueID: 1,
        totalOrder: 0,
        orderRows: []
      };
    },
    components: {
      WhiteContainer,
      Detail,
      BaseSelect,
      BaseInput,
      Btn,
      Icon,
      Counter,
      Paypal
    },
    computed: {
      ...mapState({
        user: (state) => state.user.data.user,
        clientId: (state) => state.ping.data.paypal_client_id,
      }),
    },
    created() {
        this.$store.dispatch('getFeeAmount', this.user)
        .then((response) => {
            let orderResponse = response.data.order;
            this.order = orderResponse;
            this.order.total_amount = Math.round(response.data.orderAmount * 100) / 100;
            this.totalOrder = Math.round(response.data.orderAmount * 100) / 100;
            this.orderRows = response.data.order.order_rows;
        })
    },
    methods: {
    },
    watch: {
      formData: {
        handler(newValue, oldValue) {
          // Note: `newValue` will be equal to `oldValue` here
          // on nested mutations as long as the object itself
          // hasn't been replaced.
          this.paymentUniqueID++;
        },
        deep: true
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
//   .purchase-credits {
//     &__field {
//       width: 100%;
//     }
//     .wrapper-valuta  {
//       height: 100%;
//     }
//     .wrapper-totali  {
//       h5 {
//         margin-top: 1rem;
//         margin-bottom: 1rem;
//       }
//     }
//     h4 {
//       margin-bottom: 0.5rem;
//     }
//   }
  </style>
  