import NotificationService from '@/services/NotificationService';

export default {
  state: {
    data: [],
  },
  getters: {
  },
  mutations: {
    SET_NOTIFICATIONS_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchNotifications({ commit, getters }) {
      NotificationService.getAll(getters.dealerID)
        .then((response) => {
          commit('SET_NOTIFICATIONS_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    readNotifications({ getters }) {
      NotificationService.marksAllRead(getters.dealerID)
        .catch((error) => {
          throw error;
        });
    },
  },
  modules: {
  },
};
