<template>
  <Header v-if="user"/>

  <div class="w-full main-content-wrapper">
    <div class="container-lg" v-show="modalButton && user">
      <div class="notice warning" :class="{'blur' : modalOpened}">
        <div class="icon-wrapper">
          <icon icon="Systems---information-fill" size="1rem"/>
        </div>
        <div class="action-wrapper">
          <div class="notice-text">
            <div class="subscription-error">
              {{noticeText}} <span v-show="modalButton" @click="openPaymentModal" class="payment-info-button">Clicca qui per i dettagli di pagamento</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-lg" v-if="!show_notice && isSubscriptionPaymentVisible && currentRoute !== 'subscription-payment' && currentRoute !== 'thank-you'">
      <div class="notice" :class="{'blur' : modalOpened}">
        <div class="icon-wrapper">
          <icon icon="Systems---information-fill" size="1rem"/>
        </div>
        <div class="action-wrapper">
          <div class="notice-text">
            <div class="subscription-error">
              {{noticeText}}
            </div>
            <router-link  v-if="user.current_subscription && user.current_subscription.status !== 'processing' && user.current_subscription.status !== 'created' && !this.modalButton" class="ml-2 subscription-cta" :to="{name: 'subscription-payment'}">
              Sottoscrivi abbonamento
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view :class="{'blur' : modalOpened}"/>
  </div>
  <Modal v-if="modalOpened"/>
  <div class="footer-wrap text--xs w-full flex justify-center text-center">
    <div>&copy; Partslab S.R.L. 2022</div>
    |
    <a :href="termConditionLink" target="_blank">Termini e Condizioni</a>
    <span v-if="user">|
      <a href="#" @click="logout()">Esegui Logout</a>
    </span>
  </div>
</template>

<script>
import Header from '@/components/organisms/Header.vue';
import Modal from '@/components/molecules/Modal.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/it';

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.locale('it');

export default {
  name: 'App',
  components: {
    Header,
    Modal,
    Icon,
  },
  created() {
    this.$store
      .dispatch('setUserDataStore');
    this.$store
      .dispatch('fetchPingData');
    setInterval(
      () => {
        this.$store.dispatch('fetchPingData');
      },
      10000,
      this,
    );
    this.$store
      .dispatch('fetchDiscoveryData');
  },
  data() {
    return {
      show_notice: false,
      maintenancePeriod: {
        startDate: dayjs("28/09/2022", "DD/MM/YYYY"),
        endDate: dayjs("04/10/2022", "DD/MM/YYYY"),
        text: 'Sistema di pagamento in manutenzione. Ti ricordiamo, per il solo mese di ottobre, di provvedere al rinnovo del servizio tramite bonifico bancario.'
      },
      bankTransferReminder: {
        startDate: dayjs("05/10/2022", "DD/MM/YYYY"),
        endDate: dayjs("12/10/2022", "DD/MM/YYYY"),
        text: 'Pagamento scaduto. Ti ricordiamo che il rinnovo del servizio, solo per ottobre, va effettuato con bonifico bancario. Contatta il nostro referente commerciale per maggiori informazioni oppure',
        hasModal: false
      },
      paymentExpired: {
        startDate: dayjs("13/10/2022", "DD/MM/YYYY"),
        endDate: dayjs("15/10/2022", "DD/MM/YYYY"),
        text: 'Pagamento scaduto. Ad oggi non risulta comunicato il rinnovo del servizio tramite bonifico bancario. Ti invitiamo ad effettuare le opportune verifiche amministrative, poiché interverrà il blocco del servizio di preventivazione. Clicca qui per i dettagli di pagamento.',
        hasModal: false
      },
      modalButton: false,
    };
  },
  mounted() {
  },
  computed: {
    ...mapState({
      modalOpened: (state) => state.ui.modal.isOpen,
      user: (state) => state.user.data.user,
      termConditionLink() {
        return process.env.VUE_APP_TERM_CONDITION_LINK;
      },
    }),
    currentRoute() {
      return this.$route.name;
    },
    isSubscriptionPaymentVisible() {
      if (!this.user || this.user.current_subscription == null) {
        return false;
      }
      return ['processing', 'created', 'canceled', 'ended', 'error'].includes(this.user.current_subscription?.status);
    },
    noticeText() {

      let textToShow = '';
      if (dayjs().isBetween(this.maintenancePeriod.startDate, this.maintenancePeriod.endDate) && this.user) {
        this.modalButton = true;
        textToShow = this.maintenancePeriod.text;
      }
      if (dayjs().isBetween(this.bankTransferReminder.startDate, this.bankTransferReminder.endDate) && this.user) {
        this.modalButton = true;
        textToShow = this.bankTransferReminder.text;
      }
      if (dayjs().isBetween(this.paymentExpired.startDate, this.paymentExpired.endDate) && this.user) {
        this.modalButton = true;
        textToShow = this.paymentExpired.text;
      }

      if (this.user && this.user.current_subscription) {
        if(dayjs(this.user.current_subscription.ends_at).isAfter(this.paymentExpired.endDate.endOf('day'))) {
          this.modalButton = false;
        }

        if(this.user.current_subscription.status != 'active') {
          this.modalButton = false;
        }
      }

      if(this.modalButton) {
        return textToShow;
      }

      this.modalButton = false;

      if (!this.user || this.user.current_subscription == null) {
        return '';
      }
      let noticeText = '';
      switch (this.user.current_subscription?.status) {
        case 'created':
            noticeText = 'Attenzione: la procedura di abbonamento è stata avviata ma non è ancora completa. Se il problema persiste, contattare il proprio referente.';
            break;
        case 'processing':
            noticeText = 'Attenzione: la procedura di abbonamento è in fase di elaborazione. Alcuni metodi di pagamento potrebbero richiedere più tempo.';
            break;
        case 'canceled':
            noticeText = 'Attenzione: il tuo abbonamento è stato disdetto.';
            break;
        case 'ended':
            noticeText = 'Attenzione: il tuo abbonamento è terminato.';
            break;
        case 'error':
            noticeText = 'Attenzione: c\'è stato un errore nel tuo ultimo pagamento di abbonamento. Ti preghiamo di completare nuovamente la procedura.';
            break;
        default:
            noticeText = '';
            break;
      }
      return noticeText;
    },
  },
  methods: {
    hideNotice() {
      this.show_notice = false;
    },
    openPaymentModal() {
      this.$store.dispatch('openModal', { component: 'PaymentInfos', data: [] });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/style/global.scss';
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .main-content-wrapper {
    flex-grow: 1;
  }
}
body {
  background-color: var(--bg-color);
  /*padding-bottom: var(--space-lg);*/
}
.footer-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--bg-color-content);
  padding: 1rem 0.5rem;
  margin-top: 1rem;
  & > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  & a:link, & a:visited {
    color: var(--primary-color-400);
  }
}
.blur {
  filter: blur(8px);
}
.notice {
  background-color: var(--error-color);
  &.warning {
    background-color: var(--warning-color);
  }
  color: var(--secondary-neutral-color-200);
  font-size: var(--text-sm);
  margin: var(--space-sm) 0 0 0;
  padding: var(--space-sm);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .action-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
    display: flex;
    flex-direction: column;
    .notice-button {
      align-self: flex-end;
    }
  }
  .payment-info-button {
    cursor: pointer;
    text-decoration: underline;
  }
  .notice__close {
    cursor: pointer;
  }
  .subscription-cta {
    color: var(--secondary-neutral-color-200);
    display: inline-block;
    padding: 0.5em;
    border: 1px solid var(--secondary-neutral-color-200);
    text-decoration: none;
    margin-top: 1rem;
  }
}
</style>
