<template>
	<label :for="label">
    <div v-if="showLabel" class="base-select__label">{{label}}</div>
    <select class="base-select__field" v-bind="$attrs" :id="label" v-model="model">
      <option v-for="(option, i) in options" :value="option.value" :key="option" :selected="model === null && i === 0" :disabled="option.disabled">{{option.label}}</option>
    </select>
  </label>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-select {
  &__label {
    font-size: var(--text-xs);
    text-align: left;
  }
  &__field {
    width: 100%;
  }
}
</style>
