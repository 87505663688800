<template>
	<div class="save-draft">
    <icon :icon="icon" size="1.563rem"/>
    <div class="save-draft__label text--xs">
      <template v-if="saved">Elimina <br> dalle bozze</template>
      <template v-else>Salva in <br> bozze</template>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'SaveDraft',
  props: {
    saved: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.saved ? 'Business---bookmark-fill' : 'Business---bookmark-line';
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.save-draft {
  cursor: pointer;
  color: var(--primary-color-400);
  text-align: center;
}
</style>
