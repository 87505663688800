<template>
	<div class="select" :class="{'select--opened': isSelectOpened}" v-click-outside="onClickOutside">
    <div class="select__label" @click="isSelectOpened = !isSelectOpened">
      <div class="select__counter">{{modelValue.length}}</div>
      {{label}}
      <div class="select__icon"><icon icon="Systems---arrow-down-s-line" size="1.563rem"/></div>
    </div>
    <div v-show="isSelectOpened" class="select__content">
      <div v-for="option in options" :key="option.value" @click="onOptionSelected(option.value)">
        <label class="select__option" >
          <BaseCheckbox :inputValue="option.value" v-model="model"/>
          <div class="select__option-label">
            {{option.label}}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';
import BaseCheckbox from '@/components/atoms/form/BaseCheckbox.vue';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'CustomSelect',
  emits: ['update:modelValue'],
  inheritAttrs: false,
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Icon,
    BaseCheckbox,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'single',
    },
    modelValue: {
      type: [String, Number, Array],
      default: null,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  data() {
    return {
      selected: [],
      isSelectOpened: false,
    };
  },
  methods: {
    onOptionSelected(i) {
      this.selected.push(i);
    },
    onClickOutside() {
      this.isSelectOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  cursor: pointer;
  padding: var(--space-xxs);
  color: var(--gray-color-300);
  position: relative;
  &__content {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 .1875rem .375rem #00000029;
    left: 0;
    top: 100%;
    width: 100%;
    padding: var(--space-xxs);
    max-height: 25rem;
    overflow-y: auto;
    z-index: 11;
  }
  &--opened {
    background-color: #fff;
    box-shadow: 0 .1875rem .375rem #00000029;
    color: var(--primary-color-400);
  }
  &__label {
    display: flex;
    border-bottom: 1px solid var(--gray-color-300);
    padding-bottom: var(--space-xs);
  }
  &__counter {
    margin-right: var(--space-xs);
  }
  &__icon {
    position: absolute;
    z-index: 10;
    right: .625rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &__option {
    padding: var(--space-xs) 0;
    display: flex;
    align-items: center;
  }
  &__option-label {
    margin-left: var(--space-xs);
  }
}
</style>
