<template>
	<div class="accordion">
    <div class="accordion__title" @click="isOpened = !isOpened">
      <Icon :class="{rotate: isOpened}" class="accordion__icon" icon="Systems---add-line" size="1.563rem"/>
      <slot name="title"></slot>
    </div>
    <div class="accordion__content text--sm" :class="{ 'accordion__content--open' : isOpened }">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Accordion',
  data() {
    return {
      isOpened: false,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  overflow: hidden;
  &__title {
    margin-bottom: var(--space-sm);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: var(--space-sm);
    flex-shrink: 0;
    transition: transform 0.2s;
    &.rotate {
      transform: rotate(45deg);
    }
  }
  &__content {
    max-height: 0;
    transition: all 0.4s;
    padding-left: var(--space-sm);
    &--open {
      max-height: 100vh;
    }
  }
}
</style>
