<template>
	<div class="modal">
    <div class="modal__bg" @click="closeModal()"></div>
    <div class="modal__container">
      <div class="modal__content container-md">
        <Icon class="modal__close" icon="Systems---close-circle-fill" @click="closeModal()" size="1.875rem"/>
        <component :is="modalComponent" @closeModal="closeModal" />
      </div>
    </div>
  </div>
</template>

<script>
import TrackingForm from '@/components/molecules/modals/TrackingForm.vue';
import QuotationSent from '@/components/molecules/modals/QuotationSent.vue';
import Unsubscribe from '@/components/molecules/modals/Unsubscribe.vue';
import Lightbox from '@/components/molecules/modals/Lightbox.vue';
import Payment from '@/components/molecules/modals/Payment.vue';
import UpdateCard from '@/components/molecules/modals/UpdateCard.vue';
import PaymentInfos from '@/components/molecules/modals/PaymentInfos.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import Logo from '@/components/atoms/Logo.vue';

import { mapState } from 'vuex';

export default {
  name: 'Modal',
  methods: {
    closeModal() {
      this.$store
        .dispatch('closeModal');
    },
  },
  components: {
    TrackingForm,
    QuotationSent,
    Lightbox,
    Unsubscribe,
    Payment,
    UpdateCard,
    Icon,
    PaymentInfos,
    Logo
  },
  computed: mapState({
    modalComponent: (state) => state.ui.modal.component,
  }),
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 101;
  top: 4.375rem;
  left: 0;
  right: 0;
  bottom: 0;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &__content {
    padding: var(--space-lg);
    width: 100%;
    height: 80%;
    position: relative;
    z-index: 1;

    > * {
      height: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: var(--primary-color-400);
  }
}
</style>
