<template>
	<div class="car-placeholder">
    <div class="car-placeholder__icon">
      <icon v-if="!carImage" icon="Maps---roadster-fill" size="1.875rem"/>
      <img v-else :src="carImage" :alt="carImage">
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'CarPlaceholder',
  components: {
    Icon,
  },
  props: {
    carImage: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.car-placeholder {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
  background-color: var(--secondary-neutral-color-300);
  border: 2px solid var(--gray-color-300);
  overflow: hidden;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
