<template>
  <div class="container-lg">
    <h1>Components Page</h1>
    <section>
      <h2>Logo</h2>
      <Logo/>
    </section>
    <section>
      <h2>Buttons</h2>
      <Btn class="btn__primary">Primary</Btn>
      <Btn class="btn__primary btn__primary--active">Primary Active</Btn>
      <Btn class="btn__secondary">Secondary</Btn>
      <Btn class="btn__secondary btn__secondary--active">Secondary Active</Btn>
      <Btn class="btn__tertiary">Tertiary</Btn>
      <Btn class="btn__tertiary btn__tertiary--active">Tertiary Active</Btn>
      <Btn class="btn__tertiary btn__tertiary--small">Tertiary Small</Btn>
    </section>
    <section>
      <h2>Tags</h2>
      <Tag>Base</Tag>
      <Tag class="tag__paid">Paid</Tag>
      <Tag class="tag__to-ship">To ship</Tag>
      <Tag class="tag__shipped">Shipped</Tag>
      <Tag class="tag__to-pay">To Pay</Tag>
    </section>
    <section>
      <h2>Navigations</h2>
      <Header/>
    </section>
    <section>
      <h2>Tab</h2>
      <Tab class="tab--active">Nuovi</Tab>
      <Tab counter="100">Usati</Tab>
    </section>
    <section>
      <h2>Accordion</h2>
      <Accordion>
        <template v-slot:title>
          <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequatur velit illo quia assumenda eum commodi iste cum explicabo.</h3>
        </template>
        <template v-slot:content>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto eligendi aperiam, incidunt perspiciatis ipsa asperiores corrupti sunt velit, officia, provident iusto fugit soluta laboriosam tenetur optio culpa id atque similique.

        </template>
      </Accordion>
    </section>
    <section>
      <h2>Pagination</h2>
      <Pagination :nPages="20"/>
    </section>
    <section>
      <h2>Custom Select</h2>
      <Select label="Custom Select" :options="['option 1', 'option 2', 'option 3']"/>
    </section>
    <section>
      <h2>Avarts</h2>
      <Avatar/>
      <Avatar class="avatar--tertiary-400 avatar--sm"/>
      <Avatar class="avatar--tertiary-250 avatar--xs"/>
    </section>
    <section>
      <h2>Card Dealer</h2>
        <CardDealer class="card-dealer--active">
          <template v-slot:content-left>
            <h3>#123456</h3>
            <h4>AB 123 XY FIAT - Panda Easy</h4>
            <p class="text--xs">2 Pasticche Freni <br> 1 Copri cerchi <br> 1 Ammortizzatori <br> + 5 altri elementi</p>
          </template>
          <template v-slot:footer>
            <Tag>Base</Tag>
            <Tag>Base</Tag>
            <Tag>Base</Tag>
            <SaveDraft class="ml-auto"/>
          </template>
        </CardDealer>
        <CardDealer>
          <template v-slot:content-left>
            <h3>#123456</h3>
            <h4>AB 123 XY FIAT - Panda Easy</h4>
            <p class="text--xs">2 Pasticche Freni <br> 1 Copri cerchi <br> 1 Ammortizzatori <br> + 5 altri elementi</p>
          </template>
          <template v-slot:footer>
            <Tag>Base</Tag>
            <Tag>Base</Tag>
            <Tag>Base</Tag>
            <SaveDraft class="ml-auto"/>
          </template>
        </CardDealer>
    </section>
    <section>
      <h2>Dashboard Header</h2>
      <DashboardHeader>
        <template v-slot:content-left>
          <CarText>
          <h3>#123456</h3>
          <h4>AB 123 XY FIAT - Panda Easy</h4>
          </CarText>
        </template>
        <template v-slot:content-center>
          <div>Anno 2017</div>
          <div>km 30.000</div>
          <div class="mt-space-xs"><Tag>Vedi libretto</Tag></div>
        </template>
        <template v-slot:content-right>
          <Btn class="btn__primary">Crea Preventivo</Btn>
        </template>
      </DashboardHeader>
    </section>
    <section>
      <h2>From fields</h2>
      <CheckboxSubmission id="test" value="test" v-model="test">Checkbox</CheckboxSubmission>
    </section>
  </div>
</template>

<script>
// Atoms
import Logo from '@/components/atoms/Logo.vue';
import Btn from '@/components/atoms/Btn.vue';
import Tag from '@/components/atoms/Tag.vue';
import Accordion from '@/components/atoms/Accordion.vue';
import Pagination from '@/components/atoms/Pagination.vue';
import Select from '@/components/atoms/CustomSelect.vue';
import Avatar from '@/components/atoms/Avatar.vue';
import SaveDraft from '@/components/atoms/SaveDraft.vue';
import Tab from '@/components/atoms/Tab.vue';
import CheckboxSubmission from '@/components/atoms/form/CheckboxSubmission.vue';
// Molecules
import CardDealer from '@/components/molecules/CardDealer.vue';
import CarText from '@/components/molecules/CarText.vue';
import DashboardHeader from '@/components/molecules/DashboardHeader.vue';
// Organisms
import Header from '@/components/organisms/Header.vue';

export default {
  name: 'Components',
  components: {
    Btn,
    Tag,
    Logo,
    Accordion,
    Pagination,
    Select,
    Avatar,
    CardDealer,
    SaveDraft,
    Tab,
    CarText,
    DashboardHeader,
    Header,
    CheckboxSubmission,
  },
  data() {
    return {
      test: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  margin: var(--space-xl) 0;
}
</style>
