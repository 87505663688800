<template>
  <div>
    <h5 class="my-space-xs">Tutta Italia</h5>
    <div class="mb-space-sm">
      <div class="text--sm mb-space-xxs" v-if="formData.provinces.length > 0">Escluse province: {{provincesExcluded.join(', ')}}</div>
      <em v-if="isEditing && formData.provinces.length > 0" class="text--xs text-underline cursor-pointer" @click="formData.provinces = []">Rimuovi province selezionate</em>
    </div>
    <CustomSelect v-if="isEditing" label="Escludi le province" :options="provinceOptions" v-model="formData.provinces"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import CustomSelect from '@/components/atoms/CustomSelect.vue';

export default {
  name: 'updateProvinces',
  components: {
    CustomSelect,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        provinces: [],
      },
    };
  },
  computed: {
    ...mapState({
      user_provinces: (state) => state.user.data.user.user_provinces,
    }),
    ...mapGetters([
      'provinceOptions',
    ]),
    provincesExcluded() {
      return this.provinceOptions.filter((elm) => this.formData.provinces.includes(elm.value)).map((elm) => elm.label);
    },
  },
  mounted() {
    this.formData.provinces = this.user_provinces ? this.user_provinces.map((elm) => elm.province_id) : [];
  },
};
</script>
