import axios from 'axios';
import router from '../router';

const baseURL = process.env.VUE_APP_ENDOPINT_BASEURL;

const apiClient = axios.create({
  baseURL,
});

apiClient.CancelToken = axios.CancelToken;
apiClient.isCancel = axios.isCancel;

apiClient.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('user') && localStorage.getItem('user') !== 'null') {
      const { token } = JSON.parse(localStorage.getItem('user'));
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('user');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default apiClient;
