import PartsService from '@/services/PartsService';

export default {
  state: {
    data: [],
  },
  getters: {
    partsOptions(state) {
      return state.data.filter((part) => part.part_parent_id === null).map((elm) => ({ label: elm.name, value: elm.id }));
    },
    parentPart: (state) => (id) => {
      let part = state.data.find((x) => x.id === id);
      if (part) {
        while (part.part_parent_id !== null) {
          // eslint-disable-next-line
          part = state.data.find((x) => x.id === part.part_parent_id);
        }
      }
      return part;
    },
    breadcrumbs: (state) => (id) => {
      let part = state.data.find((x) => x.id === id);
      let breadcrumbs = '';
      if (part) {
        breadcrumbs = part.name;
        while (part.part_parent_id !== null) {
          // eslint-disable-next-line
          part = state.data.find((x) => x.id === part.part_parent_id);
          breadcrumbs = `${part.name} > ${breadcrumbs}`;
        }
      }
      return breadcrumbs;
    },
  },
  mutations: {
    SET_PARTS_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchParts({ commit }) {
      PartsService.getAll()
        .then((response) => {
          commit('SET_PARTS_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  modules: {
  },
};
