<template>
	<div class="car-text">
    <div class="car-text__icon"><CarPlaceholder :carImage="carImage"/></div>
    <div class="car-text__text"><slot></slot></div>
  </div>
</template>

<script>
import CarPlaceholder from '@/components/atoms/CarPlaceholder.vue';

export default {
  name: 'CarText',
  components: {
    CarPlaceholder,
  },
  props: {
    carImage: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.car-text {
  display: flex;
  h3 {
    text-transform: uppercase;
  }
  &__text {
    flex-grow: 1;
    margin-left: var(--space-sm);
    *:first-child {
      margin-top: 0;
    }
    h1,h2,h3,h4,h5,h6 {
      margin: var(--space-xxs) 0;
    }
  }
}
</style>
