<template>
  <div>
    <div class="d-flex align-center justify-between">
      <h5>Tariffa FLAT</h5>
      <div v-if="!isEditing">{{default_flat_fee ?? 'Inserisci importo'}} €</div>
      <BaseInput v-else :showLabel="false" label="Tariffa FLAT" step="0.01" type="number" v-model="formData.default_flat_fee" required min="0"/>
    </div>
    <div class="text--xs">Tariffa applicabile su preventivo accettato in toto (la stessa può comunque essere modificata sul singolo preventivo)</div>
    <div class="d-flex align-center justify-between">
      <h5>Tariffa parziale</h5>
      <div v-if="!isEditing">{{default_partial_fee ?? 'Inserisci importo'}} €</div>
      <BaseInput v-else :showLabel="false" label="Tariffa parziale" step="0.01" type="number" v-model="formData.default_partial_fee" required min="0"/>
    </div>
    <div class="text--xs">Tariffa applicabile su preventivo accettato in parte (la stessa può comunque essere modificata sul singolo preventivo)</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseInput from '@/components/atoms/form/BaseInput.vue';

export default {
  name: 'updateFee',
  components: {
    BaseInput,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        default_flat_fee: null,
        default_partial_fee: null,
      },
    };
  },
  computed: {
    ...mapState({
      default_flat_fee: (state) => state.user.data.user.default_flat_fee,
      default_partial_fee: (state) => state.user.data.user.default_partial_fee,
    }),
  },
  mounted() {
    this.formData.default_flat_fee = this.default_flat_fee;
    this.formData.default_partial_fee = this.default_partial_fee;
  },
};
</script>
<style lang="scss" scoped>
  h5,h6 {
    margin: var(--space-xs) 0;
  }
</style>
