<template>
  <div class="search-input">
    <form @submit.prevent="$emit('onSearch')">
      <label for="search">
        <input class="search-input__field" type="text" id="search" placeholder="Cerca..." v-model="searchText">
        <Icon v-if="searchText"
          @click="handleClear()"
          class="search-input__submit clear"
          icon="Systems---close-fill"
          size="0.875rem"
          style="color: var(--primary-color-400)"
        />
        <button class="search-input__submit"><Icon icon="Systems---search-2-line" size="1.125rem"/></button>
      </label>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '../icon/Icon.vue';

export default {
  components: { Icon },
  name: 'SearchInput',
  computed: {
    ...mapState({
      search: (state) => state.dashboardFilters.search,
    }),
    searchText: {
      get() {
        return this.search;
      },
      set(value) {
        this.$store.dispatch('setSearchText', value);
      },
    },
  },
  methods: {
    handleClear(){
      this.searchText = null;
      this.$emit('onClear');
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
  &__field {
    border-radius: 3.125rem;
    padding-right: var(--space-lg);
  }
  &__submit {
    height: var(--space-lg);
    width: var(--space-lg);
    border-radius: 100%;
    border: none;
    background: #fff;
    box-shadow: 0 0.1875rem 0.375rem rgb(0 0 0 / 16%);
    position: absolute;
    right: var(--space-xxxs);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.clear {
      right: 2.75rem;
      background: none;
      box-shadow: none;
    }
  }
}
</style>
