<template>
  <div>
    <div v-if="!isEditing">{{ ibanValue }}</div>
    <BaseInput v-if="isEditing" :showLabel="false" label="Iban" type="text" v-model="ibanValue" required/>
    <p class="text--xs">I dati del tuo Iban serviranno a Parz per poterti accreditare gli acquisti effettuati dagli utenti</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseInput from '@/components/atoms/form/BaseInput.vue';

export default {
  name: 'updateIban',
  components: {
    BaseInput,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        iban: null,
      },
    };
  },
  computed: {
    ...mapState({
      ibanStore: (state) => state.user.data.user.iban,
    }),
    ibanValue: {
      get() {
        return this.formData.iban;
      },
      set(value) {
        this.formData.iban = value.replace(/\s/g, '');
      },
    },
  },
  mounted() {
    this.formData.iban = this.ibanStore;
  },
  methods: {
    validation() {
      const regex = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/;
      if (!regex.test(this.formData.iban)) {
        throw Error('Formato Iban errato');
      }
    },
  },
};
</script>
