<template lang="">
    <div style="padding:20px;margin: 0 auto;">
        <div style="display: flex;width: 100%;">
            <div style="border-right: 1px solid #112848;width: 50%;padding-right:20px;padding-bottom:10px">
                <img style="width: auto;" src="../../../assets/img/logo_parz.png" alt="logo">
                <h1 style="color:#112848;font-size:24px;">Richiesta preventivo</h1>
                <div style="border:1px solid black;width: 50%;padding:25px;position:relative;">
                    <p style="position:absolute;bottom:calc(100% - 25px);left:2px;font-size: 12px;">Nr. preventivo</p>
                </div>
            </div>

            <div style="padding-left: 20px;">
                <h2 style="margin-bottom: 10px;">Veicolo: </h2>
                <div style="">
                    <div style="margin-right: 40px;font-size: 20px;">
                        <div style="word-break: break-word;margin-bottom: 5px;">{{pdfData.vehicle.plate ? pdfData.vehicle.plate : pdfData.vehicle.chassis}}</div>
                        <div style="margin-bottom: 5px;">
                          {{manufacturer(pdfData.vehicle.manufacturer_id)?.name}}
                          {{ pdfData.vehicle.model ? ` - ${pdfData.vehicle.model}` : '' }}
                        </div>
                    </div>
                    <div style="font-size: 20px;">
                        <div style="margin-bottom: 5px;">Anno {{submissionActiveData.vehicle.year_of_production}}</div>
                        <div style="margin-bottom: 5px;">Km {{getKmValue(submissionActiveData.vehicle.km_count)}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 100%;height: 1px;background-color: black;margin-bottom: 25px;"></div>

        <table style="width: 100%;margin-bottom:20px;">
            <tr style="border-width:1px;border-color:#112848;border-style: solid;width: 800px;background-color: #9DA9B7;">
                <th style="border-right-color:#112848;border-right-style: solid; padding: 1px 6px;"
                v-for="(title, i) in tableColumnTitles" :key="i">{{ title }}</th>
            </tr>
            <tr v-for="(row, i) in pdfData.submission_rows" :key="i">
                <td>{{row.quantity}}</td>
                <td>{{breadcrumbs(row.part_id)}}</td>
                <td>{{row.part.name}}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </table>

        <div style="border:1px solid black;width: 50%;padding:60px;position:relative;">
            <p style="position:absolute;bottom:calc(100% - 25px);left:2px;font-size: 12px;">Note</p>
        </div>
    </div>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        pdfData: [Object, String, Array]
    },

    data() {
        return {
            tableColumnTitles: [
                'Quantità',
                'Categoria',
                'Nome',
                'Codice Ricambio',
                'Prezzo netto',
                'Fornitore'
            ]
        }
    },

    mounted() {
    },

    computed: mapState({
        submissionActive: (state) => state.submissions.submissionActive,
        submissions: (state) => state.submissions.data,
        totalPages: (state) => state.submissions.totalPages,
        ...mapGetters([
        'totalSubmissions',
        'submissionActiveData',
        'parentPart',
        'manufacturer',
        'getKmValue',
        'breadcrumbs',
        ]),
        ...mapState({
        user: (state) => state.user.data.user,
        }),
    }),

    methods: {
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress() {
            console.log('On progress');
        },
        hasStartedGeneration() {
            console.log('On start');
        },
        hasGenerated() {
            console.log('Done');
        },
        generateRows() {
            this.$props.submissonRows.forEach(submissionRow => {

            });
        },
    }
}
</script>
