<template>
  <div class="stripe">
    <form id="payment-form" @submit="handleSubmit">
      <div id="payment-element"></div>
        <div class="stripe__submit" v-if="isSubmitVisible">
          <Btn id="submit" class="btn__primary">Salva</Btn>
        </div>
      <div id="error-message"></div>
    </form>
  </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapState } from 'vuex';

let stripe;

export default {
  name: 'StripeUpdateCard',
  components: {
    Btn,
  },
  computed: mapState({
    clientSecret: (state) => (state.user.paymentSetupData ? state.user.paymentSetupData.client_secret : null),
    publicKey: (state) => state.ping.data.stripe_public_key,
  }),
  data() {
    return {
      elements: null,
      isSubmitVisible: false,
    };
  },
  mounted() {
    this.$store.dispatch('fetchPaymentUpdate').then(() => {
      const options = {
        clientSecret: this.clientSecret,
        appearance: {},
      };

      stripe = window.Stripe(this.publicKey);

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      this.elements = stripe.elements(options);

      // Create and mount the Payment Element
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#payment-element');

      paymentElement.on('ready', () => {
        this.isSubmitVisible = true;
      });
    });
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.$store.dispatch('openModal', { component: 'UpdateCard', data: [] });
      const { error } = await stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.VUE_APP_DOMAIN}/profile`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.$store.dispatch('paymentError', error.message);
      } else {
        alert('An unexpected error occured.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stripe {
  &__submit {
    margin-top: var(--space-md);
    text-align: center;
  }
}
</style>
