<template>
  <div class="comment-textarea">
    <label for="comment-textarea" class="comment-textarea__label">
      <input type="text" class="comment-textarea__field" id="comment-textarea" cols="10" placeholder="Scrivi un commento/domanda" v-model="message" rows="1" @keyup.enter="sendMessage"/>
      <button class="comment-textarea__submit" @click="sendMessage"><Icon icon="Business---send-plane-fill" size="1rem"/></button>
    </label>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'CommentTextarea',
  components: {
    Icon,
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    sendMessage() {
      this.$emit('onSendMessage', this.message);
      this.message = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-textarea {
  position: relative;
  &__label {
    display: flex;
  }
  &__field {
    width: 100%;
    border-radius: 3.125rem;
    padding: var(--space-sm) var(--space-xl) var(--space-sm) var(--space-sm);
    font-size: var(--text-sm);
    resize: none;
  }
  &__submit {
    position: absolute;
    right: var(--space-xxs);
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: var(--secondary-color-400);
    height: 1.75rem;
    width: 1.75rem;
    text-align: center;
    border-radius: 100%;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }
}
</style>
