<template>
  <div class="header-quotation-row">
    <div class="header-quotation-row__title">
      <div v-if="attachments" @click="viewDocument(attachments)" class="text-center attachment-icon">
        <Icon icon="Editor---attachment-2" size="1.375rem"/> <div class="text--xs">Allegati</div>
      </div>
      <h4>{{part.name}}</h4>
      <small>{{breadcrumbs(part.id)}}</small>
    </div>
    <div class="text--xs">Richiesta:</div>
    <div class="header-quotation-row__content">
      <div class="header-quotation-row__quantity">{{quantity}}</div>
      <div style="flex-grow: 1">
        <div class="header-quotation-row__elements">
          <div class="header-quotation-row__item">{{part.name}}</div>
          <div class="header-quotation-row__item text--sm">{{brand}}</div>
        </div>
        <div class="header-quotation-row__elements header-quotation-row__elements--space-beetween">
          <div class="header-quotation-row__item text--sm">{{notes}}</div>
          <div class="header-quotation-row__item"><Comments style="display: flex; gap: 10px" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Comments from '@/components/atoms/Comments.vue';

import Icon from '@/components/atoms/icon/Icon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderQuotationRow',
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    part: {
      type: Object,
      default: () => {},
    },
    brand: {
      type: String,
      default: '',
    },
    notes: {
      type: String,
      default: '',
    },
    attachments: {
      type: [Array, String],
      default: null,
    },
  },
  components: {
    Comments,
    Icon,
  },
  computed: {
    ...mapGetters([
      'breadcrumbs',
    ]),
  },
  methods: {
    viewDocument(images) {
      this.$store.dispatch('openModal', { component: 'Lightbox', data: images });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-icon {
  display: inline-block;
  float: right;
  width: max-content;
}
.header-quotation-row {
  &__title {
    margin-bottom: var(--space-sm);
    h4 {
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
    small {
      font-size: var(--text-xs);
    }
  }
  &__content {
    padding: var(--space-sm);
    background-color: var(--secondary-neutral-color-300);
    display: flex;
  }
  &__quantity {
    padding: 0 var(--space-sm);
  }
  &__elements {
    display: flex;
    &:not(:last-child) {
      margin-bottom: var(--space-sm);
    }
    &--space-beetween {
      justify-content: space-between;
    }
  }
  &__item {
    padding: 0 var(--space-sm);
  }
}
</style>
