<template>
  <div class="row">
    <div class="col-6">
      <div class="text--xs">Dati Fatturazione</div>
      <template v-if="!isEditing">
        <p>{{user.legal_name}}</p>
        <p>Tel. {{user.phone}}</p>
        <p>P.IVA {{user.vat_number}}</p>
        <p>SDI {{user.sdi}}</p>
        <p>Pec {{user.pec}}</p>
      </template>
      <template v-else>
        <BaseInput :showLabel="false" label="Ragione Sociale*" type="text" v-model="formData.legal_name" required/>
        <BaseInput :showLabel="false" label="Telefono*" type="tel" v-model="formData.phone" pattern="[-+]?\d*" minlength="7" required/>
        <BaseInput :showLabel="false" label="P.IVA*" type="text" v-model="formData.vat_number" pattern="^(\d{11})$" required/>
        <BaseInput :showLabel="false" label="SDI*" type="text" v-model="formData.sdi" pattern="[a-zA-Z0-9-]{7}" required/>
        <BaseInput :showLabel="false" label="Pec*" type="email" v-model="formData.pec" required/>
      </template>
    </div>
    <div class="col-6">
      <div class="text--xs">Sede Legale</div>
      <template v-if="!isEditing">
        <p>{{user.adr_street}}<template v-if="user.adr_house_no">, {{user.adr_house_no}}</template></p>
        <p>{{user.adr_city}}</p>
        <p>{{getProvince(user.adr_province)?.name}}</p>
        <p>{{user.adr_zip_code}}</p>
      </template>
      <template v-else>
        <BaseInput :showLabel="false" label="Via*" type="text" v-model="formData.adr_street" required/>
        <div class="row">
          <div class="col-6">
            <BaseInput :showLabel="false" label="Civico" type="text" v-model="formData.adr_house_no" required/>
          </div>
          <div class="col-6">
            <BaseInput :showLabel="false" label="Città*" type="text" v-model="formData.adr_city" required/>
          </div>
          <div class="col-6">
            <BaseSelect :showLabel="false" label="Provincia*" v-model="formData.adr_province" :options="[{value: null, label: 'Provincia', disabled: true},...provinceOptions]" required/>
          </div>
          <div class="col-6">
            <BaseInput :showLabel="false" label="Cap*" type="text" v-model="formData.adr_zip_code" pattern="^(\d{5})$" required/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import BaseInput from '@/components/atoms/form/BaseInput.vue';
import BaseSelect from '@/components/atoms/form/BaseSelect.vue';

export default {
  name: 'updateIban',
  components: {
    BaseInput,
    BaseSelect,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        email: '',
        legal_name: '',
        phone: '',
        vat_number: '',
        sdi: '',
        pec: '',
        adr_street: '',
        adr_city: '',
        adr_province: '',
        adr_zip_code: '',
        adr_house_no: '',
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data.user,
    }),
    ...mapGetters([
      'provinceOptions',
      'getProvince',
    ]),
  },
  mounted() {
    this.formData.email = this.user.email;
    this.formData.legal_name = this.user.legal_name;
    this.formData.phone = this.user.phone;
    this.formData.vat_number = this.user.vat_number;
    this.formData.sdi = this.user.sdi;
    this.formData.pec = this.user.pec;
    this.formData.adr_street = this.user.adr_street;
    this.formData.adr_city = this.user.adr_city;
    this.formData.adr_province = this.user.adr_province;
    this.formData.adr_zip_code = this.user.adr_zip_code;
    this.formData.adr_house_no = this.user.adr_house_no;
  },
};
</script>
