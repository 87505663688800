<template>
  <WhiteContainer class="tracking-form">
    <div class="container-xs">
      <template v-if="!isSent">
        <h1>CONFERMA SPEDIZIONE <br> PREVENTIVO #{{quotationActiveData.quotation_code}}</h1>
        <form @submit.prevent="addTrackingCode">
          <template v-if="isTrackingCode">
          <h3>Inserisci il tracking</h3>
          <BaseInput label="Tracking*" type="text" v-model="formData.tracking_code" required/>
          </template>
          <template v-else>
            <h3>Note spedizione</h3>
            <BaseTextarea label="Note spedizione*" v-model="formData.tracking_message" required/>
          </template>
          <div class="tracking-form__actions">
            <Btn class="btn__primary">Invia</Btn>
            <div class="tracking-form__no-code" @click="isTrackingCode = !isTrackingCode">
              <template v-if="isTrackingCode">Non</template> ho il tracking
            </div>
          </div>
        </form>
      </template>
      <template v-else>
        <h1>TRACKING #{{quotationActiveData.quotation_code}} <br> INSERITO <br> CORRETTAMENTE</h1>
        <Btn class="btn__primary" @click="closeModal()">Visualizzare Preventivi</Btn>
      </template>
    </div>
  </WhiteContainer>
</template>

<script>
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import BaseTextarea from '@/components/atoms/form/BaseTextarea.vue';
import Btn from '@/components/atoms/Btn.vue';
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TrackingForm',
  data() {
    return {
      isSent: false,
      formData: {
        tracking_message: '',
        tracking_code: '',
      },
      isTrackingCode: true,
    };
  },
  computed: mapState({
    ...mapGetters([
      'quotationActiveData',
    ]),
  }),
  components: {
    BaseInput,
    Btn,
    WhiteContainer,
    BaseTextarea,
  },
  methods: {
    addTrackingCode() {
      this.$store
        .dispatch('addTrackingCode', {
          quotationID: this.quotationActiveData.id,
          formData: this.formData,
        })
        .then(() => { this.isSent = true; })
        .catch((err) => { console.log(err); });
    },
    closeModal() {
      this.$store
        .dispatch('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.tracking-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--space-md);
  }
  &__no-code {
    cursor: pointer;
  }
}
</style>
