<template>
	<div class="pagination">
    <div class="pagination__prev" @click="changePage(1)" :class="{'pagination__disabled' : currentPage == 1}"><icon icon="first" size="1.25rem"/></div>
    <div class="pagination__prev" @click="changePage(currentPage - 1)" :class="{'pagination__disabled' : currentPage == 1}"><icon icon="previous" size="1.25rem"/></div>
    <div class="pagination__number" v-for="num in nPageVisible" :key="num" :class="{'pagination__number--active' : num == currentPage}" @click="changePage(num)">{{ num }}</div>
    <div class="pagination__next" @click="changePage(currentPage + 1)" :class="{'pagination__disabled' : currentPage == nPages}"><icon icon="next" size="1.25rem"/></div>
    <div class="pagination__next" @click="changePage(nPages)" :class="{'pagination__disabled' : currentPage == nPages}"><icon icon="last" size="1.25rem"/></div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Pagination',
  data() {
    return {
      currentPage: this.cPage,
    };
  },
  components: {
    Icon,
  },
  props: {
    nMaxPages: {
      type: Number,
      default: 5,
    },
    nPages: {
      type: Number,
      default: 1,
    },
    cPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    nPageVisible() {
      const pageRange = [];

      let i = 1;
      if (this.currentPage <= this.nPages && this.nPages - this.currentPage < this.nMaxPages - 1) {
        i = this.nPages - this.nMaxPages + 1;
      } else if (this.currentPage > 1) {
        i = this.currentPage - 1;
      }

      for (;i <= this.nPages; i++) {
        if (pageRange.length < this.nMaxPages && i > 0) {
          pageRange.push(i);
        }
      }
      return pageRange;
    },
  },
  methods: {
    changePage(num) {
      if (num > 0 && num <= this.nPages && this.currentPage != num) {
        this.currentPage = num;
        this.$emit('pageChanged', num);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  font-weight: bold;
  color: var(--primary-color-400);
  * {
    cursor: pointer;
  }
  &__disabled {
    color: var(--primary-color-250);
  }
  &__number {
    margin: 0 var(--space-sm);
    color: var(--primary-color-250);
    &--active {
      color: var(--primary-color-400);
    }
  }
}
</style>
