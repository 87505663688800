<template>
  <div class="container-lg register">
    <h1>Benvenuto in parz!</h1>
    <img class="register__logo" v-if="pingData.logo" :src="pingData.logo.vertical.color" alt="Logo">
    <form @submit.prevent="register">
      <h2>Completa i tuoi Dati, procedi all’acquisto e inizia a inviare preventivi</h2>
      <div class="register__columns">
        <div class="register__column">
          <div class="register__column-title">Il tuo account*</div>
          <BaseInput :showLabel="false" label="Email" type="email" v-model="form.email" readonly/>
          <BaseInput :showLabel="false" label="Password" type="password" v-model="form.password" @input="error ? error = '' : null" required/>
          <BaseInput :showLabel="false" label="Conferma Password" type="password" v-model="form.password_confirmation" @input="error ? error = '' : null" required/>
        </div>
        <div class="register__column">
          <div class="register__column-title">Dati fatturazione*</div>
          <BaseInput :showLabel="false" label="Ragione Sociale*" type="text" v-model="form.legal_name" required/>
          <BaseInput :showLabel="false" label="Telefono*" type="tel" v-model="form.phone" pattern="[-+]?\d*" minlength="7" required/>
          <BaseInput :showLabel="false" label="P.IVA*" type="text" v-model="form.vat_number" pattern="^(\d{11})$" required/>
          <div class="register__fields">
            <div class="register__field--1/3">
              <BaseInput :showLabel="false" label="SDI*" type="text" v-model="form.sdi" pattern="[a-zA-Z0-9-]{7}" required/>
            </div>
            <BaseInput :showLabel="false" label="Pec*" type="email" v-model="form.pec" required/>
          </div>
        </div>
        <div class="register__column">
          <div class="register__column-title">Sede legale*</div>
          <BaseInput :showLabel="false" label="Via*" type="text" v-model="form.adr_street" required/>
          <div class="register__fields">
          <BaseInput :showLabel="false" label="Civico" type="text" v-model="form.adr_house_no" required/>
          <BaseInput :showLabel="false" label="Città*" type="text" v-model="form.adr_city" required/>
          </div>
          <div class="register__fields">
            <BaseSelect :showLabel="false" label="Provincia*" v-model="form.adr_province" :options="provinces" required/>
            <BaseInput :showLabel="false" label="Cap*" type="text" v-model="form.adr_zip_code" pattern="^(\d{5})$" required/>
          </div>
        </div>
      </div>
      <div class="register__terms">
        <label>
          <BaseCheckbox required label="terms" v-model="form.terms"/> <span>Accettazione <a :href="termConditionLink" target="_blank">termini e condizioni</a></span>
        </label>
      </div>
      <div class="register__submit">
        <Btn type="submit" class="btn__primary">Conferma i dati</Btn>
      </div>
      <div v-if="error" class="error">{{error}}</div>
    </form>
  </div>
</template>

<script>
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import BaseCheckbox from '@/components/atoms/form/BaseCheckbox.vue';
import BaseSelect from '@/components/atoms/form/BaseSelect.vue';
import Btn from '@/components/atoms/Btn.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Register',
  data() {
    return {
      form: {
        email: '',
        name: '',
        surname: '',
        password: '',
        password_confirmation: '',
        legal_name: '',
        phone: '',
        vat_number: '',
        sdi: '',
        pec: '',
        adr_street: '',
        adr_city: '',
        adr_province: '',
        adr_zip_code: '',
        adr_house_no: '',
        terms: 0,
      },
      error: '',
    };
  },
  computed: mapState({
    pingData: (state) => state.ping.data,
    userData: (state) => state.user.data.user,
    ...mapGetters([
      'provinceOptions',
    ]),
    termConditionLink() {
      return process.env.VUE_APP_TERM_CONDITION_LINK;
    },
    provinces() {
      return this.provinceOptions ? [{ value: '', label: 'Provincia', disabled: true }, ...this.provinceOptions] : [];
    },
  }),
  mounted() {
    this.$store.dispatch('fetchUserData');
    this.$store.dispatch('fetchProvinceData');
    this.form.email = this.userData.email;
    this.form.name = this.userData.name;
    this.form.surname = this.userData.surname;
    this.form.legal_name = this.userData.legal_name;
    this.form.phone = this.userData.phone;
    this.form.vat_number = this.userData.vat_number;
    this.form.sdi = this.userData.sdi;
    this.form.pec = this.userData.pec;
    this.form.adr_street = this.userData.adr_street;
    this.form.adr_city = this.userData.adr_city;
    this.form.adr_province = this.userData.adr_province;
    this.form.adr_zip_code = this.userData.adr_zip_code;
    this.form.adr_house_no = this.userData.adr_house_no;
  },
  components: {
    BaseInput,
    Btn,
    BaseCheckbox,
    BaseSelect,
  },
  methods: {
    register() {
      if (this.form.password !== this.form.password_confirmation) {
        this.error = 'Le password non coincidono';
        return;
      }
      if (!this.form.terms) {
        this.error = 'Accetta i termini e condizioni';
        return;
      }

      this.$store
        .dispatch('update', {
          resource: 'updateUserData',
          formData: this.form,
        })
        .then(() => {
          this.$router.push({ name: 'subscription-payment' });
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  text-align: center;
  padding-top: var(--space-xl);
  a {
    color: inherit;
  }
  &__logo {
    margin: var(--space-lg) 0;
  }
  &__columns {
    display: flex;
    margin-top: var(--space-lg);
  }
  &__column {
    width: calc(100% / 3 - var(--space-xs));
    margin: 0 var(--space-xs);
  }
  &__column-title {
    text-align: left;
    font-size: var(--text-xs);
  }
  &__fields {
    display: flex;
    * {
      flex-grow: 1;
    }
  }
  &__field {
    &--1\/3 {
      width: calc(100% / 3);
    }
  }
  &__submit, &__terms {
    margin-top: var(--space-lg);
  }
  &__terms {
    font-weight: bold;
  }
  .error {
    margin-top: var(--space-md);
  }
}
</style>
