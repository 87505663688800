<template>
	<div class="white-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WhiteContainer',
};
</script>

<style lang="scss" scoped>
.white-container {
  background-color: #fff;
  box-shadow: 0 .1875rem .375rem #00000029;
  border-radius: .625rem;
  &--no-radius {
    border-radius: 0;
  }
}
</style>
