<template>
  <div class="container-lg">
    <h1>Faq</h1>
    <WhiteContainer>
      <div class="p-space-md" v-if="faqs">
      <Accordion v-for="faq in faqs" :key="faq.id">
        <template v-slot:title>
          <h3>{{faq.question}}</h3>
        </template>
        <template v-slot:content>
          {{faq.answer}}
        </template>
      </Accordion>
      </div>
    </WhiteContainer>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Accordion from '@/components/atoms/Accordion.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'Faq',
  components: {
    WhiteContainer,
    Accordion,
  },
  computed: {
    ...mapGetters([
      'faqs',
    ]),
  },
};
</script>
