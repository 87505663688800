<template>
	<div class="card-dealer">
    <div class="card-dealer__content">
      <div class="card-dealer__content-left">
        <CarText :carImage="carImage">
        <slot name="content-left"></slot>
        </CarText>
      </div>
      <div class="card-dealer__content-right">
        <slot name="content-right">
          <Timer/>
        </slot>
      </div>
    </div>
    <div class="card-dealer__footer">
      <slot name="footer"></slot>
    </div>
	</div>
</template>

<script>
import CarText from '@/components/molecules/CarText.vue';
import Timer from '@/components/atoms/Timer.vue';

export default {
  name: 'CardDealer',
  components: {
    CarText,
    Timer,
  },
  props: {
    carImage: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.card-dealer {
  padding: var(--space-sm);
  border-bottom: 1px solid var(--gray-color-250);
  &--active {
    background-color: var(--secondary-color-200);
  }
  &__content {
    display: flex;
    margin-bottom: var(--space-sm);
    &-left {
      flex-grow: 1;
    }
  }
  &__footer {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    * {
      margin: 0 var(--space-xxxs);
    }
  }
}
</style>
