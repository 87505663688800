import ManufacturerService from '@/services/ManufacturerService';

export default {
  state: {
    data: [],
  },
  getters: {
    manufacturerOptions(state) {
      return state.data.map((elm) => ({ label: elm.name, value: elm.id }));
    },
    manufacturer: (state) => (id) => state.data.find((manufacturer) => manufacturer.id === id),
  },
  mutations: {
    SET_MANUFACTURER_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchManufacturerData({ commit }) {
      ManufacturerService.getAll()
        .then((response) => {
          commit('SET_MANUFACTURER_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  modules: {
  },
};
