<template>
  <div class="container-lg thank-you">
      <div class="container-xs p-space-md thank-you__content">
        <div class="thank-you__icon">
          <Icon icon="Systems---checkbox-circle-fill" size="7.5rem"/>
        </div>
        <h2>{{message.title}}</h2>
        <div v-html="message.content"></div>
        <br />
        <router-link :to="{name: 'dashboard'}"><Btn class="btn__primary">torna alla Dashboard</Btn></router-link>
      </div>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Btn from '@/components/atoms/Btn.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'ThankYou',
  components: {
    WhiteContainer,
    Btn,
    Icon,
  },
  mounted() {
    this.$store.dispatch('fetchUserData');
  },
  data() {
    return {
      messages: [
        {
          slug: 'subscription',
          title: 'IL TUO PAGAMENTO È STATO INSERITO ED È IN ATTESA DI RICEZIONE',
          content: '<p>Riceverai una email di conferma del pagamento e attivazione del profilo.</p> <p>In attesa della ricezione potrai comunque accedere alla piattaforma e visualizzare le richieste di preventivo.</p>',
        },
        {
          slug: 'credits',
          title: 'ORDINE CONFERMATO',
          content: '<p>I crediti potrebbero impiegare qualche minuto prima di essere accreditati. Clicca sul bottone per tornare alla Dashboard.</p>',
        },
        {
          slug: 'fee',
          title: 'PAGAMENTO CONFERMATO',
          content: '<p>Il pagamento potrebbe impiegare qualche minuto prima di essere processato. Clicca sul bottone per tornare alla Dashboard.</p>',
        },
      ],
    };
  },
  computed: {
    message() {
      return this.messages.find((elm) => elm.slug === this.$route.params.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.thank-you {
  padding-top: var(--space-xl);
  &__icon {
    color: var(--success-color);
  }
  &__content {
    text-align: center;
  }
}
</style>
