import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../pages/Dashboard.vue';
import Login from '../pages/Login.vue';
import Register from '../pages/Register.vue';
import PasswordRecovery from '../pages/PasswordRecovery.vue';
import SubscriptionPayment from '../pages/SubscriptionPayment.vue';
import PurchaseCredits from '../pages/PurchaseCredits.vue';
import PayFee from '../pages/PayFee.vue';
import Wallet from '../pages/Wallet.vue';
import Messages from '../pages/Messages.vue';
import Notifications from '../pages/Notifications.vue';
import Feedback from '../pages/Feedback.vue';
import Profile from '../pages/Profile.vue';
import Faq from '../pages/Faq.vue';
import Components from '../pages/Components.vue';
import ThankYou from '../pages/ThankYou.vue';
import Page404 from '../pages/errors/Page404.vue';
// Dashboard
import Submissions from '../pages/Dashboard/Submissions.vue';
import Draft from '../pages/Dashboard/Draft.vue';
import Quotations from '../pages/Dashboard/Quotations.vue';

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        component: Submissions,
        name: 'dashboard',
        meta: {
          type: 'pending',
        },
      },
      {
        path: 'expired',
        component: Submissions,
        name: 'dashboard-expired',
        meta: {
          type: 'expired',
        },
      },
      {
        path: 'outlet',
        component: Submissions,
        name: 'dashboard-outlet',
        meta: {
          type: 'outlet',
        },
      },
      {
        path: 'draft',
        component: Draft,
        name: 'dashboard-draft'
      },
      {
        path: 'sent',
        component: Quotations,
        name: 'dashboard-sent',
        meta: {
          type: 'pending',
        },
      },
      {
        path: 'fulfilled',
        component: Quotations,
        name: 'dashboard-fulfilled',
        meta: {
          type: 'accepted',
        },
      },
      {
        path: 'dashboard-archived',
        component: Quotations,
        name: 'dashboard-archived',
        meta: {
          type: 'cancelled',
        },
      },
    ],
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: PasswordRecovery,
    meta: {
      title: 'Recupero Password',
    },
  },
  {
    path: '/subscription-payment',
    name: 'subscription-payment',
    component: SubscriptionPayment,
    meta: {
      title: 'Sottoscrivi Abbonamento',
    },
  },
  {
    path: '/purchase-credits',
    name: 'purchase-credits',
    component: PurchaseCredits,
    meta: {
      title: 'Acquista nuovi crediti',
    },
  },
  {
    path: '/pay-fee',
    name: 'pay-fee',
    component: PayFee,
    meta: {
      title: 'Salda fee',
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: Wallet,
    meta: {
      title: 'Wallet',
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      title: 'Messaggi',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      title: 'Notifiche',
    },
  },
  {
    path: '/feedback/:id',
    name: 'feedback',
    component: Feedback,
    meta: {
      title: 'Feedback',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Profilo',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'Faq',
    },
  },
  {
    path: '/thank-you/:slug',
    name: 'thank-you',
    component: ThankYou,
    meta: {
      title: 'Operazione avvenuta con successo',
    },
  },
  {
    path: '/components',
    name: 'components',
    component: Components,
    meta: {
      title: 'Componenti',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'page-404',
    component: Page404,
    meta: {
      title: 'Pagina non trovata',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/password-recovery'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/');
  }

  publicPages.push('/register', '/subscription-payment', '/thank-you/subscription');

  if (!publicPages.includes(to.path) && loggedIn) {
    const currentSubscription = JSON.parse(loggedIn).user.current_subscription;
    if (currentSubscription === null) {
      return next('/register');
    }
  }

  if (to.path === '/subscription-payment') {
    const currentSubscription = JSON.parse(loggedIn).user.current_subscription;
    if (currentSubscription !== null && (currentSubscription.status === 'active' || currentSubscription.status === 'pending')) {
      return next('/dashboard');
    }
  }

  if (to.path === '/' && loggedIn) {
    const currentSubscription = JSON.parse(loggedIn).user.current_subscription;
    if (currentSubscription === null) {
      return next('/register');
    }
    return next('/dashboard');
  }

  document.title = `Parz | ${to.meta.title}`;

  return next();
});

export default router;
