<template>
  <div class="container-lg notifications">
    <h1>Notifiche</h1>
    <WhiteContainer>
      <div class="p-space-md" v-if="notifications.length > 0">
        <h4>Notifiche</h4>
        <div class="mt-space-lg" v-for="notification in notifications" :key="notification.id">
          <Notify :read="notification.read === 1" :title="notification.title" :content="notification.text" :time="notification.created_at"/>
        </div>
      </div>
      <div class="p-space-md" v-else>
        <h2 style="text-align: center">NON SONO PRESENTI DATI</h2>
      </div>
    </WhiteContainer>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Notify from '@/components/atoms/Notify.vue';

import { mapState } from 'vuex';

export default {
  name: 'Notifications',
  components: {
    WhiteContainer,
    Notify,
  },
  created() {
    this.$store
      .dispatch('fetchNotifications');
    this.$store
      .dispatch('readNotifications');
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.data,
    }),
  },
};
</script>

<style lang="scss" scoped>
.notifications {}
</style>
