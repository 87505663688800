<template>
  <div class="container-lg subscription">
    <h1>Sottoscrivi abbonamento</h1>
    <div class="row">
      <WhiteContainer class="col-6">
        <div class="p-space-md">
          <div class="d-flex justify-between align-center">
            <div>Totale</div>
            <div class="text--xxl">€ {{totalOrder}}</div>
          </div>
          <h4>Dettaglio questo mese:</h4>
          <div class="mb-space-md" v-for="order in orderRows" :key="order.id">
            <Detail :itemAmount="order.item_amount" :itemCount="order.item_count" :itemName="order.item_name"/>
          </div>
          <div class="d-flex justify-end">
            <div class="text-right">
              <h5>Totale Parziale € {{totalOrder}}</h5>
              <hr>
              <h4>Questo mese Totale € {{totalOrder}}</h4>
              <h5>Prossimo mese Totale € {{dealerSubscriptionCost}}</h5>
            </div>
          </div>
        </div>
      </WhiteContainer>
      <WhiteContainer class="col-6 d-flex align-end">
        <!-- <div class="p-space-md">
          <template v-if="clientSecret">
            <Stripe :clientSecret="clientSecret" :publicKey="publicKey"/>
          </template>
        </div> -->
        <div v-if="order" style="width: 80%; margin: auto;">
          <Paypal v-if="clientId" :client_id="clientId" :order="order"></Paypal>
        </div>
        <div v-else class="col-12 d-flex flex-column align-center">
          <h2>Al momento non disponibile</h2>
          <p>Ci scusiamo per il disagio</p>
        </div>
      </WhiteContainer>
    </div>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
//import Stripe from '@/components/atoms/Stripe.vue';
import Paypal from '@/components/atoms/Paypal.vue';
import Detail from '@/components/atoms/Detail.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SubscriptionPayment',
  components: {
    WhiteContainer,
    //Stripe,
    Paypal,
    Detail,
    Icon,
  },
  created() {
    this.$store
      .dispatch('fetchPaymentSetup');
    this.$store.dispatch('fetchUserData');
  },
  computed: mapState({
    //clientSecret: (state) => (state.user.paymentSetupData ? state.user.paymentSetupData.client_secret : null),
    //publicKey: (state) => state.ping.data.stripe_public_key,
    clientId: (state) => state.ping.data.paypal_client_id,
    order: (state) => (state.user.paymentSetupData ? state.user.paymentSetupData.order : null),
    orderRows: (state) => (state.user.paymentSetupData ? state.user.paymentSetupData.order.order_rows : null),
    ...mapGetters([
      'totalOrder',
      'dealerSubscriptionCost',
    ]),
  }),
};
</script>

<style lang="scss" scoped>
.subscription {
  h2 {
    display: flex;
    * {
      margin-right: var(--space-md);
    }
  }
  h5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h4 {

  }
}
</style>
