<template>
  <div class="counter" :class="{'counter--column': inColumn}">
    <div class="counter__number-icon">
      <div class="counter__number">{{number}}</div>
      <div class="counter__icon"><slot></slot></div>
    </div>
    <div class="counter__label">
      {{label}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Counter',
  props: {
    number: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    inColumn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  align-items: center;
  text-align: center;
  &--column {
    flex-direction: column;
  }
  &__number, &__label {
    font-weight: bold;
  }
  &__number {
    font-size: var(--text-xxxl);
  }
  // &__number-icon {
  //   padding-right: var(--space-md);
  // }
  &__icon {
    color: var(--primary-color-400);
  }
  &__label {
    font-size: var(--text-xl);
  }
  &:not(.counter--column) {
    .counter__number-icon {
      min-width: 60%;
    }
    .counter__label {
      flex-grow: 1;
    }
  }
}
</style>
