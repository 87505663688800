<template>
  <div class="checkbox-submission">
    <WhiteContainer class="white-container--no-radius p-space-xs mb-space-md">
      <div class="checkbox-submission__header">
        <div class="checkbox-submission__header-left">
          <BaseCheckbox
            v-if="visible"
            :label="id"
            :inputValue="inputValue"
            v-model="model"
            :disabled="disabled"
          />
          <!-- <input class="checkbox-submission__field" type="checkbox" :id="id" :value="inputValue" v-model="model"> -->
          <div class="checkbox-submission__quantity">{{quantity}}</div>
        </div>
        <div class="checkbox-submission__header-center"><slot></slot></div>
        <div class="checkbox-submission__header-right">
          <div v-if="attachments" @click="viewDocument(attachments)" class="text-center"><Icon icon="Editor---attachment-2" size="1.375rem"/> <div class="text--xs">Allegati</div></div>
        </div>
      </div>
    </WhiteContainer>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import BaseCheckbox from '@/components/atoms/form/BaseCheckbox.vue';
import Icon from '../icon/Icon.vue';

export default {
  name: 'CheckboxSubmission',
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  components: {
    WhiteContainer,
    BaseCheckbox,
    Icon,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    inputValue: {
      type: [String, Number],
      default: '',
    },
    quantity: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    attachments: {
      type: [Array, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    viewDocument(images) {
      this.$store.dispatch('openModal', { component: 'Lightbox', data: images });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-submission {
  &__header {
    display: flex;
    &-left {
      display: flex;
      align-items: flex-start;
    }
    &-center {
      flex-grow: 1;
    }
    &-right {
      cursor: pointer;
    }
  }
  &__label {
    flex-grow: 1;
    display: flex;
  }
  &__field {
    width: 1.25rem;
    height: 1.25rem;
  }
  &__quantity {
    padding: 0 var(--space-md);
  }
  &__threads-container {
    margin-top: var(--space-sm);
    padding: var(--space-sm);
    border-top: 1px solid var(--gray-color-300);
  }
  &__threads {
    padding: 0 var(--space-lg);
  }
}
</style>
