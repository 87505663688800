import SubmissionsService from '@/services/SubmissionsService';

export default {
  state: {
    data: [],
    submissionActive: 0,
    totalPages: 0,
    currentPage: 1,
  },
  getters: {
    totalSubmissions(state) {
      return state.data.length;
    },
    submissionActiveData(state) {
      return state.data[state.submissionActive];
    },
  },
  mutations: {
    SET_SUBMISSIONS(state, submissions) {
      state.data = submissions.data;
    },
    SET_SUBMISSION_ACTIVE(state, i) {
      state.submissionActive = i;
    },
    SET_SUBMISSION(state, submission) {
      state.data.push(submission);
    },
    SET_TOTAL_SUBMISSIONS_PAGES(state, lastPage) {
      state.totalPages = lastPage;
    },
    SET_CURRENT_PAGE(state, newPage) {
      state.currentPage = newPage;
    },
    SORT_SUBMISSIONS(state) {
      // state.data = state.data.reverse();
    },
  },
  actions: {
    fetchSubmissions({ commit, dispatch, rootState }, payload) {
      let dashboardFilters = [];
      if (!payload.disableFilters) {
        dashboardFilters = rootState.dashboardFilters;
      }
      dispatch('setLoading', true, { root: true });

      return SubmissionsService.getAll(payload.filter, payload.page, dashboardFilters)
        .then((response) => {
          commit('SET_SUBMISSIONS', response.data.data);
          commit('SET_SUBMISSION_ACTIVE', 0);
          commit('SET_TOTAL_SUBMISSIONS_PAGES', response.data.data.last_page);
          commit('SET_CURRENT_PAGE', response.data.data.current_page);

          if(rootState.dashboardFilters.sort){
            // commit('SORT_SUBMISSIONS');
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          dispatch('setLoading', false, { root: true });
        });
    },
    fetchSubmission({ commit }, id) {
      return SubmissionsService.get(id)
        .then((response) => {
          commit('SET_SUBMISSIONS', { data: [] });
          commit('SET_SUBMISSION', response.data.data);
          commit('SET_SUBMISSION_ACTIVE', 0);
        })
        .catch((error) => {
          throw error;
        });
    },
    changeSubmissionActive({ commit }, i) {
      commit('SET_SUBMISSION_ACTIVE', i);
    },
    sortingSubmissions({ commit }) {
      commit('SORT_SUBMISSIONS');
    },
  },
  modules: {
  },
};
