<template>
  <LoginLayout>
    <template v-slot:title>
      <h2>RECUPERA <br> PASSWORD</h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="recoveryPassword()">
        <BaseInput class="mb-space-sm" label="Email*" type="text" v-model="email" required/>
        <Btn type="submit" class="mb-space-sm btn__primary">Invia nuova password alla email</Btn>
        <div v-if="error" class="error">{{error}}</div>
      </form>
    </template>
  </LoginLayout>
</template>

<script>
// Layout
import LoginLayout from '@/layout/LoginLayout.vue';
// Atoms
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import Btn from '@/components/atoms/Btn.vue';

export default {
  name: 'PasswordRecovery',
  data() {
    return {
      email: '',
      error: '',
    };
  },
  components: {
    LoginLayout,
    BaseInput,
    Btn,
  },
  methods: {
    recoveryPassword() {
      this.$store
        .dispatch('recoveryPassword', {
          email: this.email,
        })
        .then(() => { this.$router.push({ name: 'login' }); })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
  },
};
</script>
