<template>
  <div class="dashboard__columns" v-if="totalDrafts > 0">
    <div class="dashboard__column-left">
      <CardDealer
        v-for="(submission, i) in drafts"
        :key="submission.id"
        :class="{ 'card-dealer--active': draftActiveIndex == i }"
        @click="onCardDealerClick(i)"
        :carImage="submission.vehicle.car_photo"
      >
        <template v-slot:content-left>
          <h3 style="word-break: break-word">
            {{
              submission.vehicle.plate
                ? submission.vehicle.plate
                : submission.vehicle.chassis
            }}
          </h3>
          <h4>
            {{ manufacturer(submission.vehicle.manufacturer_id)?.name }}
            {{ submission.vehicle.model ? ` - ${submission.vehicle.model}` : '' }}
          </h4>
          <div class="text--xs">
            <div v-for="(row, i) in submission.submission_rows" :key="i">
              {{ row.quantity }} {{ parentPart(row.part.part_parent_id)?.name }}
            </div>
          </div>
        </template>
        <template v-slot:content-right>
          <Timer :time="submission.timing_residue" />
        </template>
        <template v-slot:footer>
          <Tag v-for="(row, i) in submission.submission_rows" :key="i"
            ><span v-if="parentPart(row.part_id)" style="display: flex"
              ><Icon icon="Finance---price-tag-3-fill" size="1.25rem" />
              {{ parentPart(row.part_id).name }}</span
            ></Tag
          >
        </template>
      </CardDealer>
      <div class="dashboard__pagination">
        <Pagination
          v-if="totalPages > 1"
          :nPages="totalPages"
          :cPage="currentPage"
          @pageChanged="onPageChange"
        />
      </div>
    </div>
    <div class="dashboard__column-right">
      <DashboardHeader>
        <template v-slot:content-left>
          <CarText :carImage="draftActiveData.vehicle.car_photo">
            <template v-if="draftActiveData">
              <h3 style="word-break: break-word">
                {{
                  draftActiveData.vehicle.plate
                    ? draftActiveData.vehicle.plate
                    : draftActiveData.vehicle.chassis
                }}
              </h3>
              <h4>
                {{ manufacturer(draftActiveData.vehicle.manufacturer_id)?.name }}
                {{ draftActiveData.vehicle.model ? ` - ${draftActiveData.vehicle.model}` : '' }}
              </h4>
            </template>
          </CarText>
        </template>
        <template v-slot:content-center>
          <template v-if="draftActiveData">
            <div>Anno {{ draftActiveData.vehicle.year_of_production }}</div>
            <div>km {{ getKmValue(draftActiveData.vehicle.km_count) }}</div>
          </template>
          <Btn
            v-if="draftActiveData.vehicle.registration_document"
            @click="viewDocument(draftActiveData.vehicle.registration_document)"
            class="mt-space-xs btn__tertiary btn__tertiary--small"
            style="display: inline-flex"
            ><Icon icon="Systems---eye-fill" size="1rem" /> Vedi libretto</Btn
          >
        </template>
        <template v-slot:content-right>
          <SaveDraft
            class="ml-auto"
            :saved="true"
            @click="removeDraft(draftActiveData.id)"
          />
        </template>
      </DashboardHeader>
      <div class="d-flex justify-between align-center">
        <h4>Matrice Richiesta {{ draftActiveData.vehicle.plate }}</h4>
        <Comments
          :quantity="draftActiveData.message_count"
          @click="commentsVisible = !commentsVisible"
          :active="commentsVisible"
        />
      </div>
      <div class="mb-space-lg">
        <SubmissionMessages
          v-if="commentsVisible"
          :chatToken="draftActiveData.token_uuid"
        />
      </div>
      <div
        class="more-info"
        v-if="
          (draftActiveData.notes && draftActiveData.notes !== 'N/A') ||
          draftActiveData.audio_urls ||
          draftActiveData.audio_descriptions
        "
      >
        <template
          v-if="draftActiveData.notes && draftActiveData.notes !== 'N/A'"
        >
          <p class="mb-0"><strong>Note</strong></p>
          <p class="mt-0">{{ draftActiveData.notes }}</p>
        </template>
        <template v-if="draftActiveData.audio_urls">
          <p class="mb-0"><strong>Nota audio</strong></p>
          <audio controls :key="`audio_${draftActiveData.id}`">
            <source :src="draftActiveData.audio_urls" type="audio/wav" />
            Your browser does not support the audio tag.
          </audio>
        </template>
        <template v-if="draftActiveData.audio_descriptions">
          <p class="mb-0"><strong>Trascrizione nota audio</strong></p>
          <p class="mt-0">{{ draftActiveData.audio_descriptions }}</p>
        </template>
      </div>
      <h3>Lista Articoli:</h3>
      <template v-if="draftActiveData">
        <FormQuotation :submissions="submissionRows" />
      </template>
    </div>
  </div>
  <div v-else>
    <h2 style="text-align: center">NON SONO PRESENTI DATI</h2>
  </div>
</template>

<script>
import Btn from "@/components/atoms/Btn.vue";
import CardDealer from "@/components/molecules/CardDealer.vue";
import Tag from "@/components/atoms/Tag.vue";
import Pagination from "@/components/atoms/Pagination.vue";
import DashboardHeader from "@/components/molecules/DashboardHeader.vue";
import CarText from "@/components/molecules/CarText.vue";
import Timer from "@/components/atoms/Timer.vue";
import FormQuotation from "@/components/organisms/FormQuotation.vue";
import SaveDraft from "@/components/atoms/SaveDraft.vue";
import Icon from "@/components/atoms/icon/Icon.vue";
import Comments from "@/components/atoms/Comments.vue";
import SubmissionMessages from "@/components/organisms/SubmissionMessages.vue";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Draft",
  components: {
    Btn,
    CardDealer,
    Tag,
    Pagination,
    DashboardHeader,
    CarText,
    Timer,
    FormQuotation,
    SaveDraft,
    Icon,
    Comments,
    SubmissionMessages,
  },
  data() {
    return {
      commentsVisible: false,
    };
  },
  computed: mapState({
    draftActive: (state) => state.drafts.draftActive,
    draftID: (state) => state.drafts.draftID,
    draftsLocalStorage: (state) => state.drafts.localStorage,
    currentPage: (state) => state.drafts.currentPage,
    ...mapGetters([
      "totalDrafts",
      "draftActiveData",
      "draftActiveIndex",
      "parentPart",
      "totalPages",
      "drafts",
      "manufacturer",
      "getKmValue",
    ]),
    submissionRows() {
      const draftItem = this.draftsLocalStorage.find(
        (draft) => draft.id === this.draftActiveData.id
      );
      if (draftItem) {
        const aviableSubmissionRows = draftItem.quotation_rows.map((elm) => elm.id);
        return (
          this.draftActiveData.submission_rows.filter((elm) =>
            aviableSubmissionRows.includes(elm.id)
          ) ?? []
        );
      }
      return [];
    },
  }),
  created() {
    this.$store.dispatch("resetSearchText");
    this.$store.dispatch("fetchDrafts");
  },
  methods: {
    onCardDealerClick(i) {
      this.commentsVisible = false;
      this.$store.dispatch("changeDraftActive", i);
    },
    onPageChange(newPage) {
      this.commentsVisible = false;
      this.$store.dispatch("changePage", newPage);
    },
    removeDraft(draftID) {
      this.$store.dispatch("changeDraftActive", 0);
      this.$store.dispatch("removeDraft", draftID);
      this.$store.dispatch("fetchDrafts");
    },
    viewDocument(images) {
      this.$store.dispatch("openModal", { component: "Lightbox", data: images });
    },
  },
};
</script>
