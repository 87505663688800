<template>
	<div class="comments" :class="{'active': active}">
    <icon icon="Communication---question-answer-line-1" size="1.25rem"/>
    <div class="comments__label text--xs">{{quantity}} Messaggi</div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Comments',
  components: {
    Icon,
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    active:{
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.comments {
  min-width: 7rem;
  cursor: pointer;
  color: var(--primary-color-400);
  text-align: center;
  border-radius: 2rem;
  padding: .3rem;
  &.active {
    background-color: var(--primary-color-350);
    color: var(--secondary-neutral-color-200);
  }
}
</style>
