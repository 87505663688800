<template>
  <label class="base-checkbox">
    <input class="base-checkbox__field" v-bind="$attrs" type="checkbox" :value="inputValue" v-model="model" :disabled="disabled" >
    <span class="base-checkbox__ui" :class="{'disabled' : disabled}"><icon icon="Systems---check-line"/></span>
  </label>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  props: {
    label: {
      type: [String, Number],
      default: '',
    },
    inputValue: {
      type: [String, Number],
      default: '',
    },
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  position: relative;
  cursor: pointer;
  &__ui {
    display: inline-block;
    border: .125rem solid var(--primary-color-400);
    width: 1.25rem;
    height: 1.25rem;
    color: #fff;
    &.disabled{
      opacity: 0.5;
    }
  }
  &__field {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    &:checked + * {
      background-color: var(--primary-color-400);
    }
  }
}
</style>
