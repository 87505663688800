<template>
  <component :is="type" :href="href" class="btn">
    <slot />
  </component>
</template>

<script>
export default {
  name: "Btn",
  props: {
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      return this.href ? "a" : "button";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  box-shadow: 0 0.1875rem 0.375rem #00000029;
  border-radius: 6.25rem;
  text-decoration: none;
  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.5;
  }
  &__primary {
    background-color: var(--primary-color-400);
    color: #fff;
    padding: var(--space-sm) var(--space-xl);
    font-size: var(--text-sm);
    transition: background 0.2s, color 0.2s;
    &:not(.btn__primary--active):hover {
      background-color: #fff;
      color: var(--primary-color-400);
    }
    &--active {
      background-color: var(--secondary-color-400);
      color: var(--primary-color-400);
      box-shadow: inset 0 0.1875rem 0.375rem #00000029;
    }
    &--small {
      font-weight: bold;
      padding: var(--space-sm) var(--space-md);
    }

    &:disabled {
      // opacity: 0.5;
      pointer-events: none;

      &:hover {
        background-color: var(--primary-color-400);
        color: #fff;
      }
    }
  }
  &__secondary,
  &__tertiary {
    border: 0.125rem solid var(--primary-color-400);
    color: var(--primary-color-400);
    padding: var(--space-sm);
    font-size: var(--text-xs);
    transition: border 0.2s, color 0.2s;
    &:not(.btn__secondary--active, .btn__tertiary--active):hover {
      color: var(--primary-color-300);
      border-color: var(--primary-color-300);
    }
    &--active {
      border-color: var(--secondary-color-400);
      color: var(--primary-color-300);
      box-shadow: inset 0 0.1875rem 0.375rem #00000029;
    }
  }
  &__tertiary {
    border-radius: 0.625rem;
    font-size: var(--text-sm);

    &--small {
      font-weight: bold;
      padding: var(--space-xxs);
    }
  }
}
</style>
