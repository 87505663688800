import apiClient from './apiClient';

export default {
  get(id) {
    return apiClient.get(`/submission/${id}`);
  },
  getAll(filter, page, filters) {
    return apiClient.get(`/submission/list/${filter}?page=${page}`, { params: filters });
  },
};
