import MessageService from '@/services/MessageService';

export default {
  state: {
    data: [],
  },
  getters: {
  },
  mutations: {
    SET_MESSAGES_DATA(state, pingData) {
      state.data = pingData;
    },
    SET_MESSAGE_READ(state, uuid) {
      const message = state.data.find((elm) => elm.uuid === uuid);
      message.last_message.read = true;
    },
  },
  actions: {
    fetchMessagesData({ commit, getters }) {
      return new Promise((resolve, reject) => {
        MessageService.getAll(getters.dealerID)
          .then((response) => {
            // Sort messages
            const sortedMessages = response.data.data.sort((a, b) => {
              return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
            });
            commit('SET_MESSAGES_DATA', sortedMessages);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setMessageRead({ commit }, uuid) {
      commit('SET_MESSAGE_READ', uuid);
    },
  },
  modules: {
  },
};
