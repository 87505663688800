<template>
	<div class="avatar">
    <img class="avatar__image" v-if="image" :src="image" :alt="image">
    <Icon class="avatar__placeholder" v-else icon="Users---user-3-fill"/>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'Avatar',
  components: {
    Icon,
  },
  props: {
    image: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  color: var(--primary-color-400);
  border: 2px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &--tertiary-400 {
    color: var(--tertiary-color-400);
  }
  &--tertiary-250 {
    color: var(--tertiary-color-250);
  }
  &--sm {
    width: 5rem;
    height: 5rem;
  }
  &--xs {
    width: 1.875rem;
    height: 1.875rem;
  }
  &__placeholder {
    width: 75%!important;
    height: 75%!important;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
