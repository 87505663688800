import apiClient from './apiClient';

export default {
  add(form) {
    return apiClient.put('/quotation/new', form);
  },
  getAll(filter, page, filters) {
    const dealerID = JSON.parse(localStorage.getItem('user')).user.id;
    return apiClient.get(`dealer/${dealerID}/quotation/list/${filter}?page=${page}`, { params: filters });
  },
  addTrackingCode(quotationID, formData) {
    return apiClient.post(`quotation/${quotationID}/tracking`, formData);
  },
};
