<template>
  <LoginLayout>
    <template v-slot:title>
      <h2>LOGIN</h2>
    </template>
    <template v-slot:body>
      <form @submit.prevent="login" id="print">
        <BaseInput class="mb-space-sm" label="Email*" type="text" v-model="email" required/>
        <BaseInput class="mb-space-sm" label="Password*" type="password" v-model="password" required/>
        <div class="mb-space-sm login__reset-password">
          <router-link :to="{name: 'password-recovery'}">Password dimenticata?</router-link>
        </div>
        <Btn type="submit" class="mb-space-sm btn__primary">Entra</Btn>
        <div v-if="error" class="error">{{error}}</div>
      </form>
    </template>
  </LoginLayout>
</template>

<script>
// Layout
import LoginLayout from '@/layout/LoginLayout.vue';
// Atoms
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import Btn from '@/components/atoms/Btn.vue';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  components: {
    LoginLayout,
    BaseInput,
    Btn,
  },
  methods: {
    login() {
      this.$store
        .dispatch('login', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$store
            .dispatch('fetchPingData');
          this.$router.push({ name: 'dashboard' });
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  &__reset-password {
    text-align: right;
    a {
      color: var(--primary-color-400);
    }
  }
}
</style>
