<template>
  <div class="submission-messages">
	<MessageThread class="px-space-md" :thread="messages" />
	<CommentTextarea class="mt-space-md" @onSendMessage="sendMessage" />
  </div>
</template>

<script>
import MessageThread from "@/components/molecules/MessageThread.vue";
import CommentTextarea from "@/components/atoms/form/CommentTextarea.vue";
import { mapState } from "vuex";
// Socket
const { io } = require("socket.io-client");

export default {
  name: "SubmissionMessages",
  components: {
	MessageThread,
	CommentTextarea,
  },
  data() {
	return {
	  socket: null,
	  messages: [],
	};
  },
  props: {
	chatToken: {
	  type: String,
	  default: "",
	},
  },
  computed: mapState({
	token: (state) => state.user.data.token,
  }),
  mounted() {
	this.getMessages();
  },
  unmounted() {
	if (this.socket) {
	  console.log("[INFO] Socket - Disconnecting...");
	  this.socket.off();
	  this.socket.disconnect();
	}
  },
  methods: {
	getMessages() {
	  try {
		this.messages = [];

		if (this.socket) {
		  this.socket.off();
		  this.socket.disconnect();
		}
		if (!this.token && !this.chatToken) {
			return;
		}

		this.socket = io(process.env.VUE_APP_SOCKET_URL, {
		secure: true,
		auth: {
			userToken: this.token,
			chatToken: this.chatToken,
		},
		reconnection: true,
		});

		this.readMessage();

		// SOCKET ON CONNECT
		this.socket.on(`/message/${this.chatToken}`, (message) => {
			// check if message already exists before adding
			if (!this.messages.some((m) =>
				m.text === message.text &&
				m.created_at === message.created_at &&
				m.timestamp === message.timestamp
			)) {
				this.messages.push(message);
				if (!message.my_message) this.readMessage();
			}
		});

		// SOCKET ON CONNECTION ERROR
		this.socket.on("connect_error", (error) => {
			console.log("[ERROR] Socket - Lost connection - ", error.message);
			if (this.socket.active) {
				console.log(
				"[ERROR] socket - Temporary failure, the socket will try to reconnect automatically - ",
				error.message
				);
			} else {
				console.log("[ERROR] socket - ", error.message);
				this.getMessages();
			}
		});
	  } catch (e) {
		console.error(e);
	  }
	},
	sendMessage(message) {
	  this.socket.emit(`/message/${this.chatToken}/newMessage`, {
		type: "newMessage",
		text: message,
		attachments: [],
	  });
	},

	readMessage() {
	  this.socket.emit(`/message/${this.chatToken}/newMessage`, {
		type: "confirmRead",
		text: "",
		attachments: [],
	  });
	},
  },
};
</script>
<style lang="scss" scoped>
.submission-messages {
  border-radius: 0.5rem 0.5rem 30px 30px;
  background-color: #fff;
  box-shadow: 0 0.1875rem 0.375rem #00000029;
}
</style>
