<template>
    <Btn @click="generateReport" class="mb-space-sm btn__primary">Esporta PDF</Btn>

    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="hee hee"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
        <template v-slot:pdf-content>
            <SubmissionPdf :pdfData="pdfData" v-if="isSubmission"></SubmissionPdf>
            <QuotationPdf :pdfData="pdfData" v-else></QuotationPdf>
        </template>
    </vue3-html2pdf>
</template>
<script>
import Btn from '@/components/atoms/Btn.vue';
import { mapState, mapGetters } from 'vuex';
import Vue3Html2pdf from 'vue3-html2pdf'
import SubmissionPdf from '@/components/molecules/pdf/SubmissionPdf.vue'
import QuotationPdf from '@/components/molecules/pdf/QuotationPdf.vue'

export default {
    components: {
        Btn,
        Vue3Html2pdf,
        QuotationPdf,
        SubmissionPdf
    },

    props: {
        pdfData: [Object, String, Array],
        isSubmission: Boolean
    },

    data() {
        return {
            tableColumnTitles: [
                'Quantità',
                'Categoria',
                'Nome'
            ]
        }
    },

    mounted() {
    },

    computed: mapState({
        submissionActive: (state) => state.submissions.submissionActive,
        submissions: (state) => state.submissions.data,
        totalPages: (state) => state.submissions.totalPages,
        ...mapGetters([
        'totalSubmissions',
        'submissionActiveData',
        'parentPart',
        'manufacturer',
        'getKmValue',
        'breadcrumbs',
        ]),
        ...mapState({
        user: (state) => state.user.data.user,
        }),
    }),

    methods: {
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress() {
            console.log('On progress');
        },  
        hasStartedGeneration() {
            console.log('On start');
        }, 
        hasGenerated() {
            console.log('Done');
        }, 
        generateRows() {
            this.$props.submissonRows.forEach(submissionRow => {
                
            });
        },  
    }
}
</script>