<template>
  <div v-if="showLabel" class="base-input__label">{{ label }}</div>
	<label class="base-input" :for="label">
    <div class="base-input__wrapper">
      <!-- PASSWORD INPUT -->
      <Icon v-if="type == 'password' && showPassword === false" class="base-input__password" icon="Systems---eye-fill" size="1.125rem" @click="showPassword = true"/>
      <Icon v-if="type == 'password' && showPassword" class="base-input__password" icon="Systems---eye-off-fill" size="1.125rem" @click="showPassword = false"/>
      <input v-if="type == 'password' && showPassword" class="base-input__field" v-bind="$attrs" :id="label" type="text" :placeholder="label" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">

      <!-- DATE INPUT -->
      <Datepicker v-else-if="type == 'date'"
        v-model="internalModelValue"
        :locale="locale"
        startingView="day"
        :clearable="true"
        input-format="dd MMMM yyyy"
        :upper-limit="upperDateLimit"
        :lower-limit="lowerDateLimit"
        :placeholder="label"
        >
        <template v-slot:clear="{ onClear }">
          <Icon class="base-input__password" @click="handleClear(onClear)" icon="Systems---close-fill" size="0.75rem" style="color: var(--primary-color-400);" />
        </template>
      </Datepicker>

      <!-- TEXT INPUT -->
      <input v-else class="base-input__field" v-bind="$attrs" :id="label" :type="type" :placeholder="label" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
    </div>
  </label>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';
import Datepicker from "vue3-datepicker";
import { it } from 'date-fns/locale'

export default {
  name: 'BaseInput',
  components: {
    Icon,
    Datepicker,
  },
  data() {
    return {
      showPassword: false,
      locale: it,
    };
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number, Object],
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    upperDateLimit: {
      type: Object,
      default: null,
    },
    lowerDateLimit: {
      type: Object,
      default: null,
    }
  },
  computed: {
    internalModelValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
  },
  methods: {
    handleClear(onClear){
      onClear();
      this.$emit('onClear');
    },
  }

};
</script>

<style lang="scss" scoped>
.base-input {
  display: block;
  &__wrapper {
    position: relative;
  }
  &__field {
    width: 100%;
    &:read-only {
      color: var(--text-color-light);
      cursor: not-allowed;
      pointer-events: all !important;
    }
  }
  &__label {
    font-size: var(--text-xs);
    text-align: left;
  }
  &__password {
    color: inherit;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: var(--space-xs);
    z-index: 10;
    cursor: pointer;
    & + * {
      padding-right: var(--space-lg);
    }
  }
}
:deep(.v3dp__clearable) {
  left: inherit;
}
</style>
