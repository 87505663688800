<template>
  <div class="lightbox">
    <div class="lightbox__content" v-if="imagesParsed.length > 0">
      <div class="lightbox__image">
        <Icon v-if="imagesParsed.length > 1" class="lightbox__nav-button lightbox__nav-button--left" icon="Systems---arrow-left-s-line" @click="prevImage" size="1.563rem"/>
        <img v-if="imagesParsed[currentImage]" :src="imagesParsed[currentImage]" alt="">
        <div v-else class="no-content">
          <h5 >Content not available</h5>
        </div>
        <Icon v-if="imagesParsed.length > 1" class="lightbox__nav-button lightbox__nav-button--right" icon="Systems---arrow-right-s-line" @click="nextImage" size="1.563rem"/>
      </div>
      <div class="lightbox__thumbs">
        <div class="lightbox__thumb" v-for="(image, index) in imagesParsed" :key="image" @click="changeImage(index)">
          <img v-if="image" :src="image" :alt="index">
          <div v-else>
            <p>No preview</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';
import { mapState } from 'vuex';

export default {
  name: 'Lightbox',
  components: {
    Icon,
  },
  computed: {
    ...mapState({
      images: (state) => state.ui.data,
      currentImage: (state) => state.ui.lightbox.currentImage,
    }),
    imagesParsed() {
      return JSON.parse(this.images);
    },
  },
  methods: {
    changeImage(index) {
      this.$store.dispatch('changeLightboxImage', index);
    },
    prevImage() {
      if (this.currentImage === 0) {
        this.$store.dispatch('changeLightboxImage', this.imagesParsed.length - 1);
      } else {
        this.$store.dispatch('changeLightboxImage', this.currentImage - 1);
      }
    },
    nextImage() {
      if (this.currentImage === this.imagesParsed.length - 1) {
        this.$store.dispatch('changeLightboxImage', 0);
      } else {
        this.$store.dispatch('changeLightboxImage', this.currentImage + 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lightbox {
  width: auto!important;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  &__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__image {
    position: relative;
    max-height: calc(100% - 7.5rem);
    text-align: center;
    img {
      max-height: 100%;
    }
    .no-content {
      min-width: 300px;
    }
  }
  &__nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255,255,255,0.7);
    border-radius: 50%;
    cursor: pointer;
    &--left {
      left: var(--space-sm);
    }
    &--right {
      right: var(--space-sm);
    }
  }
  &__thumbs {
    max-height: 7.5rem;
    display: flex;
    justify-content: center;
  }
  &__thumb {
    width: calc(100% / 6 - var(--space-xs));
    margin: var(--space-sm) var(--space-xs);
    cursor: pointer;
    text-align: center;
  }
}
</style>
