<template>
  <form class="form-profile" @submit.prevent="onSubmitForm">
    <div class="form-profile__title">
      <component :is="tagTitle">{{title}}</component>
      <Icon class="form-profile__toggle" icon="Design---pencil-fill" :size="iconSize" @click="isEditing = !isEditing"/>
    </div>
    <div class="form-profile__body">
      <component ref="form" :is="form" :isEditing="isEditing"></component>
    </div>
    <div v-if="isEditing" class="form-profile__footer">
      <Btn type="submit" class="btn__primary">{{submitLabel}}</Btn>
    </div>
    <div class="error" v-if="errors">
      {{errors}}
    </div>
  </form>
</template>

<script>
import Icon from '@/components/atoms/icon/Icon.vue';
import Btn from '@/components/atoms/Btn.vue';

// Forms
import updateIban from '@/components/molecules/forms-profile/updateIban.vue';
import updateFee from '@/components/molecules/forms-profile/updateFee.vue';
import updateProvinces from '@/components/molecules/forms-profile/updateProvinces.vue';
import updateUserData from '@/components/molecules/forms-profile/updateUserData.vue';
import updatePaymentMethods from '@/components/molecules/forms-profile/updatePaymentMethods.vue';
import updatePassword from '@/components/molecules/forms-profile/updatePassword.vue';

export default {
  name: 'FormProfile',
  components: {
    Icon,
    Btn,
    updateIban,
    updateFee,
    updateProvinces,
    updateUserData,
    updatePaymentMethods,
    updatePassword,
  },
  data() {
    return {
      isEditing: false,
      errors: null,
    };
  },
  props: {
    tagTitle: {
      type: String,
      default: 'h3',
    },
    title: String,
    iconSize: {
      type: String,
      default: '1.125rem',
    },
    form: {
      type: String,
      required: true,
    },
    submitLabel: {
      type: String,
      default: 'Salva modifiche',
    },
  },
  methods: {
    onSubmitForm() {
      // Custom Validation
      try {
        this.$refs.form.validation?.();
        // Update
        this.$store
          .dispatch('update', {
            resource: this.form,
            formData: this.$refs.form.formData,
          })
          .then(() => {
            this.isEditing = !this.isEditing;
            this.errors = null;
          })
          .catch((err) => {
            this.errors = err.response.data.message;
          });
      } catch (error) {
        this.errors = error.message;
      }
    },
  },
};
</script>

<style lang="scss">
.form-profile {
  &__title {
    display: flex;
    align-items: center;
    * {
      margin: 0;
    }
  }
  &__toggle {
    cursor: pointer;
    margin-left: var(--space-sm);
  }
  &__body {
    margin: var(--space-sm) 0;
  }
  &__footer {
    text-align: center;
  }
  .error {
    text-align: center;
    margin-top: var(--space-sm);
  }
}
</style>
