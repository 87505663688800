<template>
  <table class="quotations-table">
    <thead>
      <tr>
        <th>N° ORDINE</th>
        <th>DATA ACCETTAZIONE</th>
        <th>CLIENTE</th>
        <th>TARGA</th>
        <th>CREDITI</th>
        <th>FEE</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="quotation in quotations" :key="quotation.id">
        <td>#{{quotation.quotation_code}}</td>
        <td>{{formatData(quotation.updated_at)}}</td>
        <td><div v-if="quotation.user">{{quotation.user.name}} {{quotation.user.surname}}</div></td>
        <td><div v-if="quotation.vehicle">{{quotation.vehicle.plate.toUpperCase()}}</div></td>
        <td><div class="d-flex align-center">1 <Icon icon="Finance---coin-fill" size="1.125rem" class="ml-space-xs"/></div></td>
        <td><div>
          <Icon v-if="quotation.cash_unlock" icon="Systems---check-line"/>
          <Icon v-else icon="Systems---close-circle-fill"/>
        </div></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import timeMixin from '@/mixins/timeMixin';
import Icon from '@/components/atoms/icon/Icon.vue';

export default {
  name: 'QuotationsTable',
  mixins: [timeMixin],
  components: {
    Icon,
  },
  props: {
    quotations: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    checkCashUnlock(quotation) {
      return quotation.cash_unlock === 1 ? '<Icon icon="Systems---check-line"/>' : '<Icon icon="Systems---check-line"/>';
    }
  },
};
</script>

<style lang="scss" scoped>
.quotations-table {
  width: 100%;
  border-spacing: 0;
  th {
    font-size: var(--text-xl);
    padding: var(--space-sm) var(--space-lg);
  }
  tbody {
    tr:first-child {
      td {
        border-top: 1px solid var(--gray-color-300);
      }
    }
    td {
      &:not(:last-child) {
        border-right: 1px solid var(--gray-color-300);
      }
      padding: var(--space-sm) var(--space-lg);
    }
    tr:nth-child(even) {
      background: var(--secondary-neutral-color-300);
    }
  }
}
</style>
