<template>
  <div class="container-lg purchase-credits">
    <h1>Acquisto Crediti</h1>
    <div class="row">
      <WhiteContainer class="col-6">
        <div class="wrapper-valuta d-flex flex-column justify-between p-space-md">
          <div>
            <div class="mb-space-md">
              <h4>Taglio*</h4>
              <BaseSelect class="purchase-credits__field" :showLabel="false" :options="[{label: '50', value: 50,}, {label: '100', value: 100,}, {label: '200', value: 200},{label: '500', value: 500,}]" v-model="formData.credits_amount"/>
            </div>
            <div class="mb-space-md">
              <div>HAI UN COUPON?</div>
              <h4 style="margin-top: 0">Codice Sconto</h4>
              <BaseInput :showLabel="false" label="Inserisci Coupon" type="text" v-model="formData.coupon" @blur="checkCoupon"/>
            </div>
          </div>
          <div  style="margin-bottom: 2rem;">
            <div class="d-flex justify-center align-center">
              <div><Counter :number="1"><Icon icon="Finance---coin-fill" size="2.188rem"/></Counter></div>
              <div class="mx-space-md text--xxxl">=</div>
              <Counter :number="1"><Icon icon="Finance---money-euro-circle-line" size="2.188rem"/></Counter>
            </div>
            <div class="text-center text--xs">
              Tasso di cambio
            </div>
          </div>
        </div>
      </WhiteContainer>
      <WhiteContainer class="col-6 ">
        <div class="d-flex flex-column justify-between p-space-md">
          <div>
            <h4>Dettaglio:</h4>
            <div class="">
              <Detail :itemAmount="1" :itemCount="formData.credits_amount" itemName="Crediti (1credito = 1€)" />
            </div>
            <div class="wrapper-totali d-flex justify-end">
              <div class="text-right">
                <h5>Totale Parziale € {{formData.credits_amount}}</h5>
                <hr>
                <h5 v-if="coupon && coupon.usable">{{coupon.message}}, hai {{coupon.free_credits}} crediti in omaggio</h5>
                <h5>Totale € {{formData.credits_amount}}</h5>
              </div>
            </div>
          </div>
          <div style="width: 80%; margin: auto; margin-top: 2.5rem;">
            <Paypal v-if="clientId" :client_id="clientId" :order="order" :key="paymentUniqueID"></Paypal>
          </div>

        </div>
      </WhiteContainer>
    </div>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Detail from '@/components/atoms/Detail.vue';
import BaseSelect from '@/components/atoms/form/BaseSelect.vue';
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import Btn from '@/components/atoms/Btn.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import Counter from '@/components/molecules/Counter.vue';
import Paypal from '@/components/atoms/Paypal.vue';

import { mapState } from 'vuex';

export default {
  name: 'PurchaseCredits',
  data() {
    return {
      formData: {
        credits_amount: 50,
        coupon: '',
      },
      coupon: null,
      paymentUniqueID: 1,
    };
  },
  components: {
    WhiteContainer,
    Detail,
    BaseSelect,
    BaseInput,
    Btn,
    Icon,
    Counter,
    Paypal
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data.user,
      clientId: (state) => state.ping.data.paypal_client_id,
    }),
    totalOrder() {
      return this.formData.credits;
    },
    order() {
      return {
        condition: "wallet",
        total_amount: this.formData.credits_amount,
        id: false,
      }
    }
  },
  methods: {
    buyCredits() {
      this.$store
        .dispatch('buyCredits', this.formData)
        .then(() => {
          this.$router.push({ name: 'thank-you', params: { slug: 'credits' } });
        })
        .catch((err) => { console.log(err); });
    },
    checkCoupon() {
      if (this.formData.coupon) {
        this.$store
          .dispatch('checkCoupon', { coupon: this.formData.coupon })
          .then((res) => {
            this.coupon = res.data;
          })
          .catch((err) => { console.log(err); });
      }
    },
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.paymentUniqueID++;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.purchase-credits {
  &__field {
    width: 100%;
  }
  .wrapper-valuta  {
    height: 100%;
  }
  .wrapper-totali  {
    h5 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  h4 {
    margin-bottom: 0.5rem;
  }
}
</style>
