<template>
  <div class="container-lg error-layout">
    <h1>{{title}}</h1>
    <div class="error-layout__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorLayout',
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
.error-layout {
  &__content {
    margin-top: var(--space-lg);
    text-align: center;
  }
}
</style>
