<template>
  <div class="notify p-space-sm">
    <div class="notify__icon">
      <div v-if="!read" class="notify__read-circle"></div>
      <Avatar class="avatar--tertiary-250 avatar--xs"/>
    </div>
    <div class="notify__wrapper">
      <div class="notify__header">
        <h4 class="notify__title">{{title}}</h4>
        <div class="notify__time">{{fromNow(time)}}</div>
      </div>
      <div class="notify__content">{{content}}</div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar.vue';
import timeMixin from '@/mixins/timeMixin';

export default {
  name: 'Notify',
  mixins: [timeMixin],
  components: {
    Avatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    read: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.notify {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  &__icon {
    margin-right: var(--space-sm);
    display: flex;
    align-items: center;
  }
  &__read-circle {
    height: .625rem;
    width: .625rem;
    border-radius: 100%;
    background-color: var(--tertiary-color-250);
    margin-right: var(--space-xxs);
  }
  &__wrapper {
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__title {
    margin-top: 0;
    margin-bottom: var(--space-xs);
  }
  &__time, &__content {
    font-size: var(--text-sm);
  }
  &--active {
    background-color: var(--secondary-color-300) !important;
    border-radius: .5rem;
  }
  &:not(.notify--active .notify):hover {
    background-color: var(--secondary-color-200);
    border-radius: .5rem;
  }
}
</style>
