<template>
  <div>
    <h4>Metodi di pagamento</h4>
    <div class="row">
      <template v-for="(method, index) in paymentMethods" :key="method.id">
        <div class="col-12" style="margin-bottom: .5rem" v-if="method.id !== 1">
          <div class="d-flex">
            <BaseCheckbox :readonly="method.id === 4" :inputValue="method.id" v-model="formData.payment_methods" :disabled="!isEditing || (method.id === 4)"/>
            <h5>{{method.name}}</h5>
          </div>
          <p class="text--sm">{{method.description}}</p>
          <hr v-if="method.id == 4">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseCheckbox from '@/components/atoms/form/BaseCheckbox.vue';

export default {
  name: 'updatePaymentMethods',
  components: {
    BaseCheckbox,
  },
  props: {
    isEditing: Boolean,
  },
  data() {
    return {
      formData: {
        payment_methods: [],
      },
    };
  },
  computed: {
    ...mapState({
      paymentMethods: (state) => state.paymentMethods.data.reverse(),
      available_methods: (state) => state.user.data.user.available_methods,
    }),
  },
  mounted() {
    this.formData.payment_methods = this.available_methods.map((elm) => elm.id);
  },
  methods: {
    validation() {
      if (this.formData.payment_methods.length === 0) {
        throw Error('Seleziona almeno un metodo di pagamento');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h5, p {
  margin: 0 var(--space-xxs);
}
</style>
