<template>
	<div class="menu-item">
    <div class="menu-item__icon"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
};
</script>

<style lang="scss" scoped>
.menu-item {
  height: 2.8125rem;
  width: 2.8125rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .router-link-active.router-link-exact-active & {
    background-color: var(--secondary-color-400);
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color-250);
    .router-link-active.router-link-exact-active & {
      color: var(--primary-color-350);
    }
  }
}
</style>
