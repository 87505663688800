<template>
  <div class="detail">
    <div class="detail__item-count">{{itemCount}} x € {{itemAmount}}</div>
    <div class="detail__item-name">{{itemName}}</div>
    <div class="detail__item-amount">€ {{itemAmount * itemCount}}</div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    itemCount: {
      type: [Number, String],
      default: 0,
    },
    itemName: {
      type: String,
      default: '',
    },
    itemAmount: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  padding: var(--space-md);
  color: var(--text-color-light);
  border: .0625rem solid var(--text-color-light);
  display: flex;
  justify-content: space-between;
  &__item-amount {
    font-weight: bold;
    color: var(--gray-color-400);
  }
}
</style>
