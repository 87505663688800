<template>
	<div class="form-quotation">
    <form @submit.prevent="sendQuotation">
      <template v-if="submissions">
        <WhiteContainer class="white-container--no-radius p-space-xs mb-space-md" v-for="(quotation, quotationIndex) in form.quotation_rows" :key="quotation.id">
          <HeaderQuotationRowVue :quantity="submissions[quotationIndex].quantity" :part="submissions[quotationIndex].part" :brand="submissions[quotationIndex].brand" :notes="submissions[quotationIndex].notes" :attachments="submissions[quotationIndex].part_photo"/>
          <div class="form-quotation__rows">
            <div class="form-quotation__row-title">La tua offerta:</div>
            <div class="form-quotation__row-content" v-for="(option, optionIndex) in quotation.options" :key="optionIndex">
              <div class="form-quotation__row-delete" v-show="quotation.options.length > 1">
                <button type="button" class="form-quotation__row-btn-delete" @click="removeOption(quotationIndex, optionIndex)"><Icon icon="Systems---delete-bin-6-fill"/></button>
              </div>
                <div class="form-quotation__row-fields">
                  <div class="form-quotation__row-field-1/3"><BaseInput label="Quantità*" type="number" v-model="option.quantity" min="1" required/></div>
                  <div class="form-quotation__row-field-1/3"><BaseInput label="Codice Pezzo*" type="text" v-model="option.part_code" required/></div>
                  <div class="form-quotation__row-field-1/3"><BaseInput label="Marca*" type="text" v-model="option.brand" required/></div>
                  <div class="form-quotation__row-field-1/3"> <BaseSelect label="Stato*" :options="[{label: 'Nuovo', value: 'new',}, {label: 'Usato', value: 'used',}, {label: 'Ricondizionato', value: 'reconditioned',}]" v-model="option.condition"/></div>
                  <div class="form-quotation__row-field"><BaseInput label="Note" type="text" v-model="option.notes"/></div>
                  <div class="form-quotation__row-field-1/2"><BaseInput label="Prezzo*" step="0.01" type="number" v-model="option.net_price" min="0" required/></div>
                  <div class="form-quotation__row-field"><BaseSelect label="Iva*" :options="[{label: '22%', value: 22,}, {label: '10%', value: 10,}]" v-model="option.tax_import"/></div>
                  <div class="form-quotation__row-field-1/2"><BaseInput label="Prezzo listino" step="0.01" type="number" v-model="option.full_net_price" min="0"/></div>
                  <div class="form-quotation__row-field"><BaseInput label="Sconto (%)" type="number" v-model="option.discount" min="0" step="1"/></div>
                </div>
            </div>
            <div class="form-quotation__row-buttons">
              <Btn class="btn__tertiary ml-auto" @click="addOption(quotationIndex)" type="button" style="display: flex; align-items: center"><Icon icon="Systems---add-line" size="1.25rem"/> Aggiungi Opzione</Btn>
            </div>
          </div>
        </WhiteContainer>
      </template>
      <div class="form-quotation__total-pieces">
        <div>Totale Netto (opzione più economica) € {{totalPricePieces}}</div>
        <div>IVA totale € {{totalVatPieces}}</div>
      </div>
      <div class="form-quotation__total-shipping">
        <div>
          <h3>Costo Spedizione base</h3>
          <div class="form-quotation__row-fields" style="align-items: center">
            <h5 class="mr-space-md">Part Flat</h5>
            <BaseInput label="Costi di spedizione Netti" step="0.01" type="number" v-model="form.shipping_cost_flat"/>
          </div>
        </div>
        <div v-show="form.quotation_rows.length > 1">
          <h3>Costo Spedizione parziale</h3>
          <div class="form-quotation__row-fields" style="align-items: center">
            <BaseInput label="Costi di spedizione Netti" step="0.01" type="number" v-model="form.shipping_cost_partial"/>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-quotation__total">
        <h3>Totale {{total}} €</h3>
        <Btn class="btn__primary" type="submit" :disabled="sendingForm">Termina Preventivo</Btn>
      </div>
    </form>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import HeaderQuotationRowVue from '@/components/molecules/HeaderQuotationRow.vue';
import BaseInput from '@/components/atoms/form/BaseInput.vue';
import BaseSelect from '@/components/atoms/form/BaseSelect.vue';
import Btn from '@/components/atoms/Btn.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FormQuotation',
  components: {
    WhiteContainer,
    HeaderQuotationRowVue,
    BaseInput,
    BaseSelect,
    Btn,
    Icon,
  },
  props: {
    submissions: {
      type: Array,
    },
  },
  data() {
    return {
      form: {
        shipping_cost_flat: 0,
        shipping_cost_partial: 0,
        quotation_rows: [],
      },
      sendingForm: false,
    };
  },
  computed: mapState({
    user: (state) => state.user.data.user,
    draftsLocalStorage: (state) => state.drafts.localStorage,
    ...mapGetters([
      'draftActiveData',
      'dealerID',
    ]),
    optionsCheaper() {
      const optionsCheaper = [];
      this.form.quotation_rows.forEach((quotation) => {
        if (quotation.options) {
          const optionCheaper = quotation.options.reduce((prev, curr) => {
            const prevTotPrice = Number(prev.net_price || 0) * prev.quantity;
            const prevVat = (prevTotPrice * prev.tax_import) / 100;
            const prevTotalVatIncluse = prevTotPrice + prevVat || 0;
            const currTotPrice = Number(curr.net_price || 0) * curr.quantity;
            const currVat = (currTotPrice * curr.tax_import) / 100;
            const currTotalVatIncluse = currTotPrice + currVat || 0;

            return prevTotalVatIncluse < currTotalVatIncluse ? prev : curr;
          });
          optionsCheaper.push(optionCheaper);
        }
      });
      return optionsCheaper;
    },
    totalPricePieces() {
      let tot = 0;
      this.optionsCheaper.forEach((elm) => {
        tot += Number(elm.net_price || 0) * elm.quantity;
      });
      return tot ? tot.toFixed(2) : 0.00;
    },
    totalVatPieces() {
      let tot = 0;
      this.optionsCheaper.forEach((elm) => {
        const totPrice = Number(elm.net_price || 0) * elm.quantity;
        const vat = (totPrice * elm.tax_import) / 100;
        tot += vat;
      });
      return tot ? tot.toFixed(2) : 0.00;
    },
    total() {
      const shippingPrice = Number(this.form.shipping_cost_flat) + (this.form.shipping_cost_flat * 22) / 100;
      return (Number(this.totalPricePieces) + Number(this.totalVatPieces) + shippingPrice).toFixed(2);
    },
  }),
  mounted() {
    this.formDataReset();
  },
  methods: {
    sendQuotation() {
      this.sendingForm = true;
      const quotationRows = [];
      this.form.quotation_rows.forEach((element) => {
        if (element.options.length > 0) {
          element.options.forEach((option) => {
            const row = {
              submission_row_id: element.id,
              ...option,
            };
            quotationRows.push(JSON.stringify(row));
          });
        }
      });
      const formData = {
        shipping_cost_flat: this.form.shipping_cost_flat ?? 0,
        shipping_cost_partial: this.form.shipping_cost_partial ?? 0,
        submission_id: this.draftActiveData.id,
        vehicle_id: this.draftActiveData.vehicle_id,
        author_id: this.draftActiveData.author_id,
        dealer_id: this.dealerID,
        quotation_rows: quotationRows,
      };

      this.$store
        .dispatch('addQuotation', formData)
        .then(() => {
          this.$store.dispatch('removeDraft', this.draftActiveData.id);
          this.$store.dispatch('changeDraftActive', 0);
          this.$store.dispatch('openModal', { component: 'QuotationSent', data: [] });
        })
        .catch((err) => {
          console.log('ERRORE', err);
          if (err.response.data.message) {
            let errorMessage = 'Errore in invio preventivo';
            if (typeof err.response.data.message === 'string' || err.response.data.message instanceof String) {
              errorMessage = err.response.data.message;
            }

            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: errorMessage,
            });
          }
          this.sendingForm = false;
        });
    },
    formDataReset() {
      const draftActive = this.draftsLocalStorage.find((draft) => draft.id === this.draftActiveData.id);
      this.form.quotation_rows = (draftActive.quotation_rows) ? draftActive.quotation_rows : [];
      this.form.shipping_cost_flat = this.user.default_flat_fee;
      this.form.shipping_cost_partial = this.user.default_partial_fee;
    },
    addOption(i) {
      this.form.quotation_rows[i].options.push({
        quantity: 1,
        tax_import: 22,
        condition: 'new',
      });
    },
    removeOption(quotationIndex, optionIndex) {
      this.form.quotation_rows[quotationIndex].options.splice(optionIndex, 1);
    },
  },
  watch: {
    submissions() {
      this.formDataReset();
    },
    'form.quotation_rows': {
      handler(val) {
        this.$store.dispatch('updateDraft', {
          id: this.draftActiveData.id,
          quotation_rows: val,
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-quotation {
  &__row-fields {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__row-field {
    flex-grow: 1;
    margin: var(--space-xxs);
  }
  &__row-field-1\/3 {
    width: calc(100% / 3 - (var(--space-xxs) * 2));
    margin: var(--space-xxs);
  }
  &__row-field-1\/2 {
    width: calc(100% / 2 - (var(--space-xxs) * 2));
    margin: var(--space-xxs);
  }
  &__total-pieces {
    text-align: right;
    margin: var(--space-xl) 0 var(--space-md) 0;
    font-weight: bold;
    > *:not(:last-child) {
      margin-bottom: var(--space-sm);
    }
  }
  &__row-title {
    font-size: var(--text-xs);
    margin-top: var(--space-sm);
  }
  &__row-content {
    display: flex;
    align-items: flex-start;
    border-bottom: .125rem solid var(--secondary-neutral-color-400);
  }
  &__row-delete {
    padding: var(--space-lg) var(--space-sm);
  }
  &__row-btn-delete {
    height: 1.875rem;
    width: 1.875rem;
    background-color: #fff;
    border: none;
    border-radius: 100%;
    box-shadow: 0 0.1875rem 0.375rem rgb(0 0 0 / 16%);
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background-color: var(--secondary-color-400);
    }
  }
  &__row-buttons {
    display: flex;
    padding: var(--space-sm) 0;
  }
  &__total-shipping {
    width: 70%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-left: auto;
  }
  &__total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: var(--space-md);
  }
}
</style>
