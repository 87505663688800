<template>
  <div class="paypal-wrapper"> 
    <div :id="`paypal-button-container${randomized}`"></div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import { mapState } from 'vuex';

let paypal;

export default {
  name: 'Paypal',
  components: {
  },
  props: {
    client_id: {
      type: String,
    },
    order: {
      type: Object
    },
  },
  data() {
    return {
      elements: null,
      randomized: Date.now(),
    };
  },
  async mounted() {
    const paypalSdk =  await loadScript(this.sdkData);

    paypalSdk.Buttons(this.buttonsSdk).render(`#paypal-button-container${this.randomized}`);
  },
  computed: {
    ...mapState({
      pingData: (state) => state.ping.data,
      user: (state) => state.user.data.user,
      planId: (state) => state.ping.data.paypal_dealer_plan,
    }),
    sdkData: function() {
      let ppData = {
        'client-id': this.client_id,
        currency: 'EUR',
      };

      if (this.order.condition == 'dealer') {
        //è una subcription!
        ppData.vault = true;
        ppData.intent = "subscription";
      }
      return ppData;


    },
    buttonsSdk: function() {
      let buttonsData = {
        style: this.ppStyle,
        onShippingChange: this.onShippingChange,
        onError: this.onError,
        onApprove: this.onApprove,
      };

      if (this.order.condition == 'dealer') {
        buttonsData.createSubscription = this.createSubscription;
      }
      if (this.order.condition == 'wallet') {
        buttonsData.createOrder = this.createOrder;
      }

      if (this.order.condition == 'fee') {
        buttonsData.createOrder = this.createOrder;
      }

      return buttonsData;
      
    },
    createOrder: function () {
      return (data, actions) => {

        let orderRef = {
          create: this.order.id ? false : true,
          amount: this.order.total_amount,
          user: this.user.id,
          condition: this.order.condition,
        };

        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: "EUR",
                value: Math.round((this.order.total_amount * 1.22) * 100) / 100,
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: this.order.total_amount,
                  },
                  shipping: {
                    currency_code: "EUR",
                    value: 0
                  },
                  tax_total: {
                    currency_code: "EUR",
                    value: Math.round(this.order.total_amount * 0.22 * 100) / 100 
                  }
                }
              },
              custom_id: this.order.id,
              reference_id: JSON.stringify(orderRef),
            },
          ],
        });
      }
    },
    createSubscription: function() {
      return (data, actions) => {
        return actions.subscription.create({
          'plan_id': this.planId,
          custom_id: this.order.id,
        });
      }
    },
    onApprove: function () {
      return (data, actions) => {
        this.$store.dispatch('openModal', { component: 'Payment', data: [] });

        let that = this;

        if(this.order.condition == "dealer") {

          const ppForm = {
            order_id: this.order.id,
            status: 'pending',
            external_id: data.subscriptionID,
          };
          

          this.$store.dispatch('updateOrder', ppForm)
            .then(() => {

              that.$router.push({ name: 'thank-you', params: { slug: 'subscription' } });
              that.$store.dispatch('closeModal', { component: 'Payment', data: [] });
            })
            .catch((err) => { console.log(err); });

            return;
        }

        if (this.order.condition == "fee") {
            return actions.order.capture().then(async function(details) {
            that.$router.push({ name: 'thank-you', params: { slug: 'fee' } });   
            that.$store.dispatch('closeModal', { component: 'Payment', data: [] });    
          });
        }

        return actions.order.capture().then(async function(details) {
          that.$router.push({ name: 'thank-you', params: { slug: 'credits' } });   
          that.$store.dispatch('closeModal', { component: 'Payment', data: [] });    
        });
      }
    },
    onShippingChange: function () {
      return (data, actions) => {
        if (data.shipping_address.country_code !== 'IT') {
          return actions.reject();
        }
        return actions.resolve();
      }
    },
    onError: function () {
      return (err) => {
        console.error(err);
      }
    },
    ppStyle: function () {
      return {
        shape: 'pill',
        color: 'gold',
        layout: 'vertical',
        label: 'pay',
        tagline: false
      }
    },
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.$store.dispatch('openModal', { component: 'Payment', data: [] });
      const { error } = await stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${process.env.VUE_APP_DOMAIN}/thank-you/subscription`,
        },
      });

      if (error.message) {
        this.$store.dispatch('paymentError', error.message);
      } else {
        this.$store.dispatch('paymentError', 'Errore inaspettato!');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paypal-wrapper {
  /*padding: var(--space-md)*/
}
</style>
