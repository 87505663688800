import dayjs from 'dayjs';
dayjs.locale('it');

export default {
  methods: {
    formatData(data) {
      return dayjs(data).format('DD/MM/YYYY');
    },
    fromNow(data) {
      return dayjs(data).fromNow();
    },
  },
};
