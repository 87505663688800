import apiClient from './apiClient';

export default {
  getAll(userId) {
    return apiClient.get(`/user/${userId}/notification/list`);
  },
  marksAllRead(userId) {
    return apiClient.get(`/user/${userId}/notification/read`);
  },
};
