<template>
  <ErrorLayout title="Page not Found">
    <h2>La pagina che stai cercando non è stata trovata</h2>
    <div class="my-space-lg">
      <img :src="require('@/assets/img/404.png')" alt="404">
    </div>
    <router-link :to="{name: 'dashboard'}"><Btn class="btn__primary">torna alla Dashboard</Btn></router-link>
  </ErrorLayout>
</template>

<script>
// Layout
import ErrorLayout from '@/layout/ErrorLayout.vue';
import Btn from '@/components/atoms/Btn.vue';

export default {
  name: 'Page404',
  components: {
    Btn,
    ErrorLayout,
  },
};
</script>

<style scoped lang="scss">
.page-404 {
  &__content {
    margin-top: var(--space-lg);
    text-align: center;
  }
}
</style>
