import QuotationService from '@/services/QuotationService';

export default {
  state: {
    data: [],
    totalPages: 0,
    currentPage: 1,
    quotationActive: 0,
  },
  getters: {
    totalQuotations(state) {
      return state.data.length;
    },
    quotationActiveData(state) {
      return state.data[state.quotationActive];
    },
  },
  mutations: {
    SET_QUOTATIONS(state, quotations) {
      state.data = quotations.data;
    },
    SET_QUOTATION_ACTIVE(state, i) {
      state.quotationActive = i;
    },
    ADD_QUOTATION(state, quotation) {
      state.data.push(quotation);
    },
    SET_TOTAL_QUOTATIONS_PAGES(state, lastPage) {
      state.totalPages = lastPage;
    },
    UPDATE_QUOTATION_DATA(state, data) {
      const index = state.data.findIndex((elm) => elm.id === data.id);
      state.data[index] = data;
    },
    SORT_QUOTATIONS(state) {
      state.data = state.data.reverse();
    },
  },
  actions: {
    addQuotation({ commit }, form) {
      return new Promise((resolve, reject) => {
        QuotationService.add(form)
          .then(() => {
            resolve();
            commit();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchQuotations({ commit, dispatch, rootState }, payload) {
      let dashboardFilters = [];
      if (!payload.disableFilters) {
        dashboardFilters = rootState.dashboardFilters;
      }

      dispatch('setLoading', true, { root: true });

      return QuotationService.getAll(payload.filter, payload.page, dashboardFilters)
        .then((response) => {
          commit('SET_QUOTATIONS', response.data.data);
          commit('SET_QUOTATION_ACTIVE', 0);
          commit('SET_TOTAL_QUOTATIONS_PAGES', response.data.data.last_page);

          if(rootState.dashboardFilters.sort){
            commit('SORT_QUOTATIONS');
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          dispatch('setLoading', false, { root: true });
        });
    },
    changeQuotationActive({ commit }, i) {
      commit('SET_QUOTATION_ACTIVE', i);
    },
    addTrackingCode({ commit }, payload) {
      return new Promise((resolve, reject) => {
        QuotationService.addTrackingCode(payload.quotationID, payload.formData)
          .then((response) => {
            commit('UPDATE_QUOTATION_DATA', response.data.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    sortingQuotations({ commit }) {
      commit('SORT_QUOTATIONS');
    },
  },
  modules: {
  },
};
