<template>
  <div class="dashboard__columns" v-if="totalSubmissions > 0">
    <div class="dashboard__column-left">
      <CardDealer
        v-for="(submission, i) in submissions"
        :key="submission.id"
        :class="submissionActive == i ? 'card-dealer--active' : ''"
        @click="onCardDealerClick(i)"
        :carImage="submission.vehicle.car_photo"
      >
        <template v-slot:content-left>
          <div v-if="hasSubmissionBeenAnswered(submission)">
            <div class="quotation-sent-label">
              <strong
                ><icon icon="Business---Preventivi-line" size="0.8rem" /><span
                  class="text--xs ml-space-xxxs"
                  >Preventivo inviato!</span
                ></strong
              >
            </div>
          </div>
          <h3 style="word-break: break-word">
            {{
              submission.vehicle.plate
                ? submission.vehicle.plate
                : submission.vehicle.chassis
            }}
          </h3>
          <h4>
            <span v-if="submission.vehicle?.manufacturer_id">{{ manufacturer(submission.vehicle.manufacturer_id)?.name }}</span>
            {{ submission.vehicle.model ? ` - ${submission.vehicle.model}` : '' }}
          </h4>
          <div class="text--xs">
            <template v-for="(row, i) in submission.submission_rows" :key="i">
              <div v-if="i <= 2">
                {{ row.quantity }}
                {{
                  row.part.part_parent_id !== null
                    ? parentPart(row.part.part_parent_id)
                      ? parentPart(row.part.part_parent_id).name
                      : row.part?.name
                    : row.part?.name
                }}
              </div>
            </template>
            <div v-if="submission.submission_rows.length > 3">
              + {{ submission.submission_rows.length - 3 }} altri elementi
            </div>
          </div>
        </template>
        <template v-slot:content-right>
          <Timer :time="submission.timing_residue" :frozen="submission.is_frozen" />
        </template>
        <template v-slot:footer>
          <Tag v-for="(row, i) in submission.submission_rows" :key="i"
            ><span v-if="parentPart(row.part_id)" style="display: flex"
              ><Icon icon="Finance---price-tag-3-fill" size="1.25rem" />
              {{ parentPart(row.part_id).name }}</span
            ></Tag
          >
          <SaveDraft v-if="$route.meta.type != 'expired'" class="ml-auto" @click="addDraft(submission.id)" />
        </template>
      </CardDealer>
      <div class="dashboard__pagination">
        <Pagination
          v-if="totalPages > 1"
          :nPages="totalPages"
          @pageChanged="onPageChange"
        />
      </div>
    </div>
    <div class="dashboard__column-right">
      <DashboardHeader>
        <template v-slot:content-left>
          <CarText :carImage="submissionActiveData.vehicle.car_photo">
            <template v-if="submissionActiveData">
              <h3 style="word-break: break-word">
                {{
                  submissionActiveData.vehicle.plate
                    ? submissionActiveData.vehicle.plate
                    : submissionActiveData.vehicle.chassis
                }}
              </h3>
              <h4>
                {{ manufacturer(submissionActiveData.vehicle.manufacturer_id)?.name }}
                {{ submissionActiveData.vehicle.model ? ` - ${submissionActiveData.vehicle.model}` : '' }}
              </h4>
            </template>
          </CarText>
        </template>
        <template v-slot:content-center>
          <template v-if="submissionActiveData">
            <div>Anno {{ submissionActiveData.vehicle.year_of_production }}</div>
            <div>Km {{ getKmValue(submissionActiveData.vehicle.km_count) }}</div>
          </template>
          <Btn
            v-if="submissionActiveData.vehicle.registration_document"
            @click="viewDocument(submissionActiveData.vehicle.registration_document)"
            class="mt-space-xs btn__tertiary btn__tertiary--small"
            style="display: inline-flex"
            ><Icon icon="Systems---eye-fill" size="1rem" /> Vedi libretto</Btn
          >
        </template>
        <template v-slot:content-right v-if="$route.meta.type != 'expired'">
          <Btn
            :disabled="!submissionActiveData.submission_rows.length"
            class="btn__primary"
            @click="addDraft(submissionActiveData.id)"
            style="display: flex; text-align: left; padding: var(--space-sm)"
            ><icon icon="Business---Preventivi-line" size="2.188rem" /> CREA <br />
            PREVENTIVO</Btn
          >
          <template v-if="!submissionActiveData.submission_rows.length">
            <p class="mt-2 mb-0 text--xs text-right" style="color: darkorange">
              <strong>NOTA: Non è possibile creare preventivi per questo tipo di richiesta.</strong>
            </p>
          </template>
        </template>
      </DashboardHeader>

      <div>Data {{formatData(submissionActiveData.created_at)}}</div>
      <div class="d-flex justify-between align-center">
        <h3 style="margin-bottom: 0">Richiesta preventivo:</h3>
      </div>
      <div class="messages-info d-flex justify-between align-center mb-space-sm">
        <div class="messages-info-cta" style="flex-grow: 1; flex-shrink: 1">
          Problemi con il preventivo?<br />
          Se scrivi al cliente per maggiori informazioni, il preventivo andrà in pausa in
          attesa della risposta!
        </div>
        <Comments
          :quantity="submissionActiveData.message_count"
          @click="commentsVisible = !commentsVisible"
          :active="commentsVisible"
        />
      </div>
      <div
        class="more-info"
        v-if="
          (submissionActiveData.notes && submissionActiveData.notes !== 'N/A') ||
          submissionActiveData.audio_urls ||
          submissionActiveData.audio_descriptions
        "
      >
        <template
          v-if="submissionActiveData.notes && submissionActiveData.notes !== 'N/A'"
        >
          <p class="mb-0"><strong>Note</strong></p>
          <p class="mt-0">{{ submissionActiveData.notes }}</p>
        </template>
        <template v-if="submissionActiveData.audio_urls">
          <p class="mb-0"><strong>Nota audio</strong></p>
          <audio controls :key="`audio_${submissionActiveData.id}`">
            <source :src="submissionActiveData.audio_urls" type="audio/wav" />
            Your browser does not support the audio tag.
          </audio>
        </template>
        <template v-if="submissionActiveData.audio_descriptions">
          <p class="mb-0"><strong>Trascrizione nota audio</strong></p>
          <p class="mt-0">{{ submissionActiveData.audio_descriptions }}</p>
        </template>
      </div>
      <div class="mb-space-lg">
        <SubmissionMessages
          v-if="commentsVisible"
          :chatToken="submissionActiveData.token_uuid"
        />
      </div>
      <template v-if="submissionActiveData">
        <CheckboxSubmission
          v-for="row in submissionActiveData.submission_rows"
          :key="row.id"
          :id="row.id"
          :inputValue="row.id"
          v-model="submissionsRows"
          :quantity="row.quantity"
          :attachments="row.part_photo"
          :disabled="$route.meta.type == 'expired'"
          :visible="$route.meta.type != 'expired'"
        >
          <div class="text--xs mb-space-xxxs">{{ breadcrumbs(row.part_id) }}</div>
          <div class="mb-space-xxxs">{{ row.part.name }}</div>
          <div class="text--sm mb-space-xxxs">{{ row.brand }}</div>
          <div v-if="row.notes" class="text--xs mb-space-xxxs">Note: {{ row.notes }}</div>
        </CheckboxSubmission>
        <PdfTemplate :pdfData="submissionActiveData" :isSubmission="true"></PdfTemplate>
      </template>
    </div>
  </div>
  <div v-else>
    <h2 style="text-align: center">NON SONO PRESENTI DATI</h2>
  </div>
</template>

<script>
import Btn from "@/components/atoms/Btn.vue";
import CardDealer from "@/components/molecules/CardDealer.vue";
import Tag from "@/components/atoms/Tag.vue";
import Pagination from "@/components/atoms/Pagination.vue";
import DashboardHeader from "@/components/molecules/DashboardHeader.vue";
import CarText from "@/components/molecules/CarText.vue";
import CheckboxSubmission from "@/components/atoms/form/CheckboxSubmission.vue";
import Timer from "@/components/atoms/Timer.vue";
import SaveDraft from "@/components/atoms/SaveDraft.vue";
import Icon from "@/components/atoms/icon/Icon.vue";
import Comments from "@/components/atoms/Comments.vue";
import SubmissionMessages from "@/components/organisms/SubmissionMessages.vue";
import { mapState, mapGetters } from "vuex";
import PdfTemplate from "@/components/organisms/PdfTemplate.vue";
import timeMixin from "@/mixins/timeMixin";

export default {
  name: "Submissions",
  mixins: [timeMixin],
  components: {
    Btn,
    CardDealer,
    Tag,
    Pagination,
    DashboardHeader,
    CarText,
    Timer,
    CheckboxSubmission,
    SaveDraft,
    Icon,
    Comments,
    SubmissionMessages,
    PdfTemplate,
  },
  created() {
    this.$store.dispatch("resetSearchText");
    this.$store.dispatch("fetchSubmissions", { filter: this.$route.meta.type, page: 1 });
  },
  watch: {
    $route() {
      this.$store.dispatch("resetSearchText");
      this.$store.dispatch("fetchSubmissions", {
        filter: this.$route.meta.type,
        page: 1,
      });
    },
  },
  data() {
    return {
      submissionsRows: [],
      commentsVisible: false,
    };
  },
  computed: mapState({
    submissionActive: (state) => state.submissions.submissionActive,
    submissions: (state) => state.submissions.data,
    totalPages: (state) => state.submissions.totalPages,
    ...mapGetters([
      "totalSubmissions",
      "submissionActiveData",
      "parentPart",
      "manufacturer",
      "getKmValue",
      "breadcrumbs",
    ]),
    ...mapState({
      user: (state) => state.user.data.user,
    }),
  }),
  methods: {
    fetchData() {
      this.$store.dispatch("fetchSubmissions", {
        filter: this.$route.meta.type,
        page: 1,
      });
    },
    onCardDealerClick(i) {
      this.commentsVisible = false;
      this.$store.dispatch("changeSubmissionActive", i);
      this.submissionsRows = [];
    },
    onPageChange(newPage) {
      this.commentsVisible = false;
      this.$store.dispatch("fetchSubmissions", {
        filter: this.$route.meta.type,
        page: newPage,
      });
      this.submissionsRows = [];
    },
    addDraft(submissionID) {
      const submission = this.submissions.find((elm) => elm.id === submissionID);
      if (this.submissionsRows.length === 0) {
        this.submissionsRows = submission.submission_rows.map((elm) => elm.id);
      }
      const draft = {
        id: submission.id,
        quotation_rows: this.submissionsRows.map((elm) => ({
          id: elm,
          options: [
            {
              quantity: 1,
              tax_import: 22,
              condition: "new",
            },
          ],
        })),
      };
      this.$store
        .dispatch("addDraft", draft)
        .then(() => {
          this.$router.push({ name: "dashboard-draft" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sortingData() {
      this.commentsVisible = false;
      this.$store.dispatch("sortingSubmissions");
    },
    viewDocument(images) {
      this.$store.dispatch("openModal", { component: "Lightbox", data: images });
    },
    hasSubmissionBeenAnswered(submission) {
      if (submission.quotations.length) {
        const dealerHasAnswered = submission.quotations.findIndex(
          (sub) => sub.dealer_id === this.user.id
        );
        return dealerHasAnswered > -1;
      }
      return false;
    },
  },
};
</script>
<style>
.quotation-sent-label {
  display: inline-flex;
  align-items: center;
  padding: var(--space-xxxs);
  border: 1px solid var(--primary-color-400);
}
.messages-info {
  font-size: var(--text-xs);
}
.messages-info .messages-info-cta {
  color: var(--primary-color-400);
}
</style>
