<template>
	<label class="base-textarea" :for="label">
    <div v-if="showLabel" class="base-textarea__label">{{label}}</div>
    <textarea v-bind="$attrs" class="base-textarea__field" rows="5" :id="label" @input="$emit('update:modelValue', $event.target.value)"></textarea>
  </label>
</template>

<script>
export default {
  name: 'BaseTextarea',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-textarea {
  &__field {
    width: 100%;
  }
  &__label {
    font-size: var(--text-xs);
    text-align: left;
  }
}
</style>
