export default {
  state: {
    data: [
      { id: 1, value: '0 - 25.000' },
      { id: 2, value: '25.000 - 70.000' },
      { id: 3, value: '70.000 - 120.000' },
      { id: 4, value: '120.000 - 170.000' },
      { id: 5, value: '170.000 - 200.000' },
      { id: 6, value: '200.000 +' },
    ],
  },
  getters: {
    getKmValue: (state) => (id) => state.data.find((elm) => elm.id === Number(id))?.value || 'N.D',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
};
