<template>
  <nav class="dashboard-nav">
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard'}"><Tab :animate="true" :key="pingData.dealer_pending_submissions_count" :counter="pingData.dealer_pending_submissions_count">In scadenza</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-outlet'}"><Tab :animate="true"  :key="pingData.dealer_outlet_submissions_count"
        :counter="pingData.dealer_outlet_submissions_count">Outlet</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-draft'}"><Tab :counter="totalDrafts">Bozze</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-sent'}"><Tab :counter="pingData.dealer_sended_quotations_count">Inviati</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-fulfilled'}"><Tab :counter="pingData.dealer_accepted_quotations_count">Accettati</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-archived'}"><Tab :counter="pingData.dealer_cancelled_quotations_count">Archivio</Tab></router-link>
    </div>
    <div class="dashboard-nav__item">
      <router-link :to="{name: 'dashboard-expired'}"><Tab :key="pingData.dealer_pending_submissions_count" :counter="pingData.dealer_expired_submissions_count">Scaduti</Tab></router-link>
    </div>
  </nav>
</template>

<script>
import Tab from '@/components/atoms/Tab.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'DashboardNav',
  components: {
    Tab,
  },
  computed: mapState({
    pingData: (state) => state.ping.data,
    ...mapGetters([
      'totalDrafts',
    ]),
  }),
};
</script>

<style lang="scss" scoped>
.dashboard-nav {
  display: flex;
  position: relative;
  a {
    text-decoration: none;
  }
  &__item {
    padding: var(--space-sm);
    flex-grow: 1;
  }
}
</style>
