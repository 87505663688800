<template>
  <div class="stars-review">
    <Icon v-for="i in starts.fill" icon="Systems---star-s-fill" size="1.875rem" :key="i"/>
    <Icon v-if="starts.half" icon="Systems---star-half-s-line" size="1.875rem"/>
    <Icon v-for="i in starts.empty" icon="Systems---star-s-line" size="1.875rem" :key="i"/>
  </div>
</template>

<script>
import isFloat from '@/helpers/calcs';

import Icon from '../atoms/icon/Icon.vue';

export default {
  name: 'StartsReview',
  props: {
    vote: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Icon,
  },
  computed: {
    starts() {
      return {
        fill: Math.floor(this.vote),
        half: isFloat(this.vote) ? 1 : 0,
        empty: (5 - Math.floor(this.vote) - (isFloat(this.vote) ? 1 : 0)),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.stars-review {
  color: var(--primary-color-400);
}
</style>
