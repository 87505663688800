import apiClient from './apiClient';
import { sha256 } from 'js-sha256';

export default {
  get() {
    const hashComune = process.env.VUE_APP_HASH_COMUNE;
    const saltHash = [...Array(64)].map(() => Math.random().toString(36)[2]);
    const salt = saltHash.filter((c, i) => i % 2).join('').substring(12, 24);
    const security = JSON.stringify({ f: window.btoa(sha256(`${salt}${hashComune}${salt}`)), v: window.btoa(saltHash.join('')) });

    return apiClient.get('/ping', {
      params: { security: security }
    });
  },
};
