<template>
    <div class="container-xs login-layout">
      <div class="login-layout__logo">
        <img v-if="pingData.logo" :src="pingData.logo.vertical.color" alt="Logo">
        <slot name="title"/>
      </div>
      <div class="p-space-md">
        <slot name="body"/>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoginLayout',
  computed: mapState({
    pingData: (state) => state.ping.data,
  }),
};
</script>

<style lang="scss" scoped>
.login-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 4.375rem);
  text-align: center;
  padding: 0 var(--space-xxl);
}
</style>
