import { createStore } from 'vuex';

import user from './modules/user';
import parts from './modules/parts';
import submissions from './modules/submissions';
import quotations from './modules/quotations';
import feedback from './modules/feedback';
import drafts from './modules/drafts';
import ping from './modules/ping';
import discovery from './modules/discovery';
import notifications from './modules/notifications';
import province from './modules/province';
import manufacturer from './modules/manufacturer';
import paymentMethods from './modules/paymentMethods';
import kilometers from './modules/kilometers';
import dashboardFilters from './modules/dashboardFilters';
import messages from './modules/messages';
import ui from './modules/ui';

export default createStore({
  modules: {
    user,
    parts,
    submissions,
    quotations,
    feedback,
    drafts,
    ping,
    discovery,
    notifications,
    province,
    manufacturer,
    paymentMethods,
    kilometers,
    dashboardFilters,
    messages,
    ui,
  },
});
