<template>
	<div class="price">
    <div class="price__total text--xxl">{{price}} €</div>
    <div class="price__time text--xs">Timing residuo {{time}}'</div>
  </div>
</template>

<script>

export default {
  name: 'Price',
  props: {
    time: {
      type: Number,
      default: 0,
    },
    price: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  text-align: right;
  &__total {
    font-weight: bold;
    color: var(--primary-color-400);
  }
}
</style>
