import UserService from '@/services/UserService';
import UploadFileService from '@/services/UploadFileService';

export default {
  state: {
    data: {
      token: null,
      user: null,
    },
    paymentSetupData: null,
    provinces: [],
  },
  getters: {
    dealerID(state) {
      return state.data.user.id;
    },
    dealerSubscriptionCost(state) {
      return state.data.user.subscription_cost != null ? state.data.user.subscription_cost : 100;
    },
    totalOrder(state) {
      let total = 0;
      if (state.paymentSetupData) {
        state.paymentSetupData.order.order_rows.forEach((element) => {
          total += element.item_amount * element.item_count;
        });
        if (state.order_coupon) {
          total -= state.order_coupon;
        }
      }
      return total;
    },
  },
  mutations: {
    SET_USER_DATA(state, data) {
      state.data.user = data;
    },
    SET_USER_PROVINCES(state, data) {
      state.provinces = data;
    },
    SET_USER_TOKEN(state, token) {
      state.data.token = token;
    },
    SET_USER_DATA_LOCAL_STORAGE(state, userData) {
      localStorage.setItem('user', JSON.stringify(userData));
    },
    SET_USER_DATA_FROM_LOCAL_STORE(state) {
      const user = localStorage.getItem('user');
      if (user) {
        state.data = JSON.parse(user);
      }
    },
    REMOVE_USER_DATA_STORE() {
      localStorage.clear();
    },
    SET_PAYMENT_SETUP_DATA(state, data) {
      state.paymentSetupData = data;
    },
  },
  actions: {
    login({ commit }, credentials) {
      return UserService.login(credentials.email, credentials.password)
        .then(({ data }) => {
          commit('SET_USER_DATA', data.data.user);
          commit('SET_USER_TOKEN', data.data.token);
          commit('SET_USER_DATA_LOCAL_STORAGE', data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    logout({ commit }) {
      commit('REMOVE_USER_DATA_STORE');
      commit('SET_USER_DATA', null);
      commit('SET_USER_TOKEN', null);
    },
    recoveryPassword({ commit }, formData) {
      return new Promise((resolve, reject) => {
        UserService.recoveryPassword(formData)
          .then(() => {
            commit();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setUserDataStore({ commit }) {
      commit('SET_USER_DATA_FROM_LOCAL_STORE');
    },
    fetchPaymentSetup({ commit, getters }) {
      return UserService.getPaymentSetup(getters.dealerID)
        .then((response) => {
          commit('SET_PAYMENT_SETUP_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchPaymentUpdate({ commit, getters }) {
      return UserService.getPaymentUpdate(getters.dealerID)
        .then((response) => {
          commit('SET_PAYMENT_SETUP_DATA', response.data.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    buyCredits({ commit, getters }, formData) {
      return new Promise((resolve, reject) => {
        UserService.buyCredits(getters.dealerID, formData)
          .then(() => {
            resolve();
            commit();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateOrder({ commit }, formData) {
      return new Promise((resolve, reject) => {
        UserService.updateOrder(formData)
          .then(() => {
            resolve();
            commit();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkCoupon({ commit, getters }, formData) {
      return new Promise((resolve, reject) => {
        UserService.checkCoupon(getters.dealerID, formData)
          .then(({ data }) => {
            resolve(data);
            commit();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchUserData({ commit, state }) {
      return new Promise((resolve, reject) => {
        UserService.get()
          .then(({ data }) => {
            commit('SET_USER_DATA', data);
            commit('SET_USER_DATA_LOCAL_STORAGE', {
              token: state.data.token,
              user: data,
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchUserProvinces({ commit, getters }) {
      return new Promise((resolve, reject) => {
        UserService.getProvinces(getters.dealerID)
          .then(({ data }) => {
            commit('SET_USER_PROVINCES', data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    update({ commit, getters, state }, payload) {
      return new Promise((resolve, reject) => {
        UserService[payload.resource](getters.dealerID, payload.formData)
          .then(({ data }) => {
            commit('SET_USER_DATA', data.data);
            commit('SET_USER_DATA_LOCAL_STORAGE', {
              token: state.data.token,
              user: data.data,
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updatePhoto({ commit, getters, state }, formData) {
      return new Promise((resolve, reject) => {
        UserService.updatePhoto(getters.dealerID, formData)
          .then(({ data }) => {
            commit('SET_USER_DATA', data.data);
            commit('SET_USER_DATA_LOCAL_STORAGE', {
              token: state.data.token,
              user: data.data,
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadUserImage({ dispatch }, image) {
      return new Promise((resolve, reject) => {
        UploadFileService.upload(image)
          .then(({ data }) => {
            dispatch('updatePhoto', { profile_photo: data.data })
              .then((response) => {
                resolve(response.data);
              }).catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFeeAmount({ dispatch, getters }, formData) {
      return new Promise((resolve, reject) => {
        UserService.getFeeAmount(getters.dealerID, formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },
  modules: {
  },
};
